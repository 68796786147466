import {Fragment, useContext, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import XIcon from '@heroicons/react/outline/XIcon'
import axios from "axios";
import ProductCard from "../product-card";
import Filters, {buildESQueryBody, buildFiltersFromParam, clearFilters, toggleFilters} from "./filters";
import Pagination from "./pagination";
import useDebounce from "../../hooks/use-debounce";
import {WebsiteContext} from "../../index";
import Sort from "./sort";
import ActiveFilters from "./active-filters";
import classNames from "classnames";
import Theme from "../../theme";

export default function Design1(props) {
    const context = useContext(WebsiteContext)
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState({});
    const [data, setData] = useState({filters: [], products: [], pages: null, categories: []});
    const [size, setSize] = useState(24);
    const productsRef = useRef(null);
    
    const search = useDebounce((cancelToken) => {
        const {criteria, aggsFilters} = buildESQueryBody(activeFilters, size, context?.website?.store);

        let data = {
            body: criteria,
            categoryUri: props.values.uri,
            categoryUuid: props.values.uuid,
            aggsFilters,
            activeFilters
        };

        axios
            .post('/products/search', data, {cancelToken: cancelToken.token})
            .then(({data}) => {
                setData({
                    filters: data.filters,
                    products: data.products,
                    pages: Math.ceil(data.products?.total?.value / size) || 1,
                    categories: data.categories,
                    storeOptions: data.storeSettingOptions
                })
            })
            .catch((err) => {
                if (err.message !== 'canceled') console.log(err)
            });
    }, 300, axios.CancelToken.source());

    useEffect(() => {
        buildFiltersFromParam(setActiveFilters);

        window.addEventListener('popstate', () => buildFiltersFromParam(setActiveFilters));
        return () => {
            window.addEventListener('popstate', () => buildFiltersFromParam(setActiveFilters));
        };
    }, [])

    useEffect(() => {
        search();
    }, [activeFilters])

    if (data.products?.hits?.length === 0 && data.pages && +activeFilters['page'] > data.pages) {
        setActiveFilters((previous) => ({...previous, page: [data.pages]}))
    }

    return (
        <div>
            {/* Mobile filter */}
            <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                <Dialog as="div" className={classNames(
                    "relative z-40 sm:hidden acx"
                )} onClose={setMobileFiltersOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>
                    <div className={classNames(
                        `heading_${context?.website?.theme?.selectedFont?.headingName?.split(' ').join('_').toLowerCase()}`,
                        `body_${context?.website?.theme?.selectedFont?.bodyName?.split(' ').join('_').toLowerCase()}`,
                        "fixed preview inset-0 z-40 flex"
                    )}>
                        <Theme settings={context?.website?.theme}/>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full">
                            <Dialog.Panel
                                className="relative flex h-full w-full flex-col overflow-y-auto bg-white">
                                <div
                                    className="flex items-center justify-between p-4 border-b border-gray-200 sticky top-0 bg-white z-10">
                                    <h3 className="!mb-0">Filters</h3>
                                    <button
                                        type="button"
                                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                        onClick={() => setMobileFiltersOpen(false)}>
                                        <span className="sr-only">Close filter</span>
                                        <XIcon className="h-6 w-6 text-gray-700" aria-hidden="true"/>
                                    </button>
                                </div>
                                <Filters filters={data.filters}
                                         className={"p-4"}
                                         activeFilters={activeFilters} uri={props.values.uri}
                                         categories={data.categories}
                                         storeOptions={data.storeOptions}
                                         setActiveFilters={setActiveFilters}/>
                                <div
                                    className={"sticky bottom-0 px-4 py-6 border-t section-white flex flex-row justify-between items-center gap-8"}>
                                    <button className={"button secondary w-full justify-center"}
                                            onClick={() => {
                                                clearFilters(activeFilters, setActiveFilters)
                                            }}>Clear Filters
                                    </button>
                                    <button className={"button w-full justify-center"}
                                            onClick={() => setMobileFiltersOpen(false)}>View&nbsp;
                                        {data.products?.total?.value !== undefined && data.products?.total?.value !== null && (
                                            data.products.total.value
                                        )}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="sm:mx-auto max-w-7xl min-h-screen" ref={productsRef}>
                <div>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-4">
                        {data.products?.total?.value !== undefined && data.products?.total?.value !== null &&
                            <Filters filters={data.filters} 
                                     className="pt-6 pl-8 pr-4 mb-8 overflow-y-auto sticky top-[var(--site-nav-height)] h-fit sm:max-h-[calc(100vh-var(--site-nav-height))] hidden sm:block"
                                     uri={props.values.uri}
                                     categories={data.categories}
                                     storeOptions={data.storeOptions}
                                     productsList={productsRef}
                                     activeFilters={activeFilters} setActiveFilters={setActiveFilters}/>
                        }
                        <div className="sm:col-span-3 pb-24 pt-6 px-4 sm:pt-8 sm:pr-8 sm:!pl-0">
                            <div className="flex flex-row items-center justify-between text-base font-medium text-gray-700 mt-2">
                                {data.products?.total?.value !== undefined && data.products?.total?.value !== null && (
                                    <div><span className={"font-semibold"}>{data.products.total.value}</span> results</div>
                                )}
                                <div className="flex items-center">
                                    {/*Sort Menu*/}
                                    {context?.website?.store?.filters?.sort && data.products?.total?.value !== undefined && data.products?.total?.value !== null &&
                                        <Sort sortFields={context?.website?.store?.fields?.sort}
                                              activeFilters={activeFilters}
                                              onClick={(v) => toggleFilters(v, 'sort', activeFilters, setActiveFilters, false, productsRef)}/>}
                                    {data.products?.total?.value !== undefined && data.products?.total?.value !== null &&
                                        <button
                                            type="button"
                                            className="ml-2 sm:!hidden block button"
                                            onClick={() => setMobileFiltersOpen(true)}>
                                            <span className="sr-only">Open Filter</span>
                                            Filter
                                        </button>
                                    }
                                </div>
                            </div>
                            {/*Active Filters*/}
                            { data.products?.total?.value !== undefined && data.products?.total?.value !== null &&
                                <ActiveFilters activeFilters={activeFilters} 
                                               setActiveFilters={setActiveFilters}                                  
                                               productsList={productsRef}
                                />
                            }
                            {/*Product List*/}
                            {data.products?.hits && (
                                <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
                                    {data.products?.hits?.map((product) => (
                                        <ProductCard key={product._id} id={product._id} product={product._source}
                                                     settings={{design: product._source?.design}}
                                                     values={{options: {imageAspectRatio: context?.website?.store?.productCardAspectRatio}}}/>
                                    ))}
                                </div>
                            )}
                            {data.products?.hits?.length === 0 && (
                                <div>
                                    <h4 className={"!mb-0 mt-2"}>No Results</h4>
                                    <p>Try removing some filters</p>
                                </div>
                            )}
                            {data.products?.hits && (<div className="mt-6">
                                <Pagination
                                    onPreviousClick={() => {
                                        const page = activeFilters['page'] ? +activeFilters['page'] - 1 : 1;
                                        toggleFilters(page, 'page', activeFilters, setActiveFilters, false, productsRef)
                                    }}
                                    onNextClick={() => {
                                        const page = activeFilters['page'] ? +activeFilters['page'] + 1 : 2;
                                        toggleFilters(page, 'page', activeFilters, setActiveFilters, false, productsRef)
                                    }}
                                    onPaginationClick={(pageNumber) => {
                                        toggleFilters(pageNumber, 'page', activeFilters, setActiveFilters, false, productsRef)
                                    }}
                                    pages={data.pages} currentPagination={activeFilters['page']}
                                />
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}