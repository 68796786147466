import {useContext, useEffect, useState} from "react";
import axios from "axios";
import Design1 from "./design-1";
import Design2 from "./design-2";
import useApplyUserDefinedListOverrides from "../../hooks/use-user-defined-list-sort";
import {WebsiteContext} from "../../index";
import {makeItems, filterItems} from "../../utils/utils";

export default function GalleryBlock(props) {
    const context = useContext(WebsiteContext);
    const [items, setItems] = useState(props?.values?.items || []);

    // Use the custom hook to filter items based on hideOn values
    const displayItems =
        props?.values?.source === "collection"
            ? filterItems(items)
            : filterItems(props.values.items);

    // Apply any user defined list overrides
    const values = useApplyUserDefinedListOverrides({
        ...props,
        values: {
            ...props?.values,
            items: displayItems,
        },
    });

    // Fetch items if using collection source
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (context.builder && props?.values?.source === "collection") {
            axios
                .get(`/api/v2/channels/${context.channel}/nodes/${props.values.schema}/`, {cancelToken: cancelToken.token})
                .then(({data}) => {
                    const children = data?.[0]?.children;
                    setItems(makeItems(children, props?.values?.item));
                })
                .catch(err => {
                    if (!axios.isCancel(err)) console.log(err);
                });
        }
        return () => cancelToken.cancel();
    }, [props?.values, context.builder, context.channel]);

    // Render based on design prop
    switch (props.values?.design) {
        case 0:
            return <Design1 {...props} values={values}/>;
        case 1:
            return <Design2 {...props} values={values}/>;
        default:
            return <Design1 {...props} values={values}/>;
    }
}