import PropTypes from 'prop-types';
import classNames from 'classnames';
import {WebsiteContext} from "../index";
import {useContext} from "react";
import _lastIndexOf from "lodash/lastIndexOf"
import useCdn from "../hooks/cdn-hook";

Action.propTypes = {
    showingActionButton: PropTypes.bool,
    button: PropTypes.bool,
    style: PropTypes.object,
    actionStyle: PropTypes.oneOf(['noStyle', 'link', 'button', 'buttonFull']),
    className: PropTypes.string,
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    action: PropTypes.shape({
        type: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        uniqueUri: PropTypes.string,
        target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    }),
    defaultHref: PropTypes.string,
};

Action.defaultProps = {
    showingActionButton: false,
    button: true,
    defaultHref: '/',
    style: {}
};

export const getActionLink = (action, context, defaultHref) => {
    const uniqueUri = action?.uniqueUri ?? '';
    const uuid = (uniqueUri?.includes('/')) ? uniqueUri?.substring(_lastIndexOf(uniqueUri, '/') + 1) : uniqueUri
    let href;

    switch (action.type) {
        case 'page':
            href = (action.uniqueUri ? context.actionLookup[uuid] : undefined) ?? action.value ?? defaultHref;
            break;
        case 'folder':
        case 'link':
            href = action.value ?? defaultHref;
            break;
        case 'checkout':
            href = `product/${context?.website?.workspaceId}_${action.productUuid}/checkout`;
            break;
        case 'product':
            href = `product/${action.productUuid}`;
            break;
        case 'block':
            href = `${context?.actionLookup[context?.node?.uuid] ?? ''}#block_${action.value}`;
            break;
        case 'file':
            href = useCdn(action.value, action.type) ?? defaultHref;
            break;
        case 'category':
            href = (action.uniqueUri ? context.actionLookup[action.uniqueUri] : undefined) ?? defaultHref;
            break;
        default:
            href = (action.uniqueUri ? context.actionLookup[uuid] : undefined) ?? action.value ?? defaultHref;
    }

    return href;
}

// Function to convert URL path to readable text
const convertUrlPathToReadableText = (path) => {
    // Remove leading/trailing slashes
    const trimmed = path.replace(/^\/+|\/+$/g, '');
    // Split into words on hyphen or underscore
    const words = trimmed.split(/[-_]/);
    // Capitalize each word and join with spaces
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

/**
 * A standardised way of rendering an action link for a website page. This handles the action object defined in block
 * specifications (including site navigation)
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function Action(props) {
    const context = useContext(WebsiteContext);
    const action = getActionProperties(props);
    if (!props.showingActionButton || !action) return null;

    const href = getActionLink(action, context, props.defaultHref);

    // handle legacy button prop and preferred actionStyle for primary button or list item button
    let determinedActionStyle = action.actionStyle || props.itemActionStyle || (props.button ? 'button' : 'noStyle');
    return (
        <a style={props.style} className={classNames(
            determinedActionStyle === 'link' ? 'action-link' : ['button', 'buttonFull'].includes(determinedActionStyle) ? 'button' : null,
            determinedActionStyle === 'buttonFull' ? 'w-full justify-center' : null,
            context.builder || !href ? "pointer-events-none" : null,
            props.className
        )}
           title={determinedActionStyle === 'noStyle' ? props.title : null}
           href={props.children?.length ? null : href}
           target={action.type === 'file' ? '_blank' : action.target}
           aria-label={convertUrlPathToReadableText(href)}>
            {props.startAdornment}
            {action.label}
            {props.endAdornment}
            {props.children}
        </a>
    );
};

/**
 * Gets a standard action object definition. This is due to site navigation block settings fields having different
 *  names to blocks.
 *
 *  TODO: Migrate site navigation settings fields to
 *        actionLabel => action.label
 *        actionValue => action.value
 *
 * @param props
 */
function getActionProperties(props) {
    const action = {
        ...(props.action || {}),
        label: props.actionLabel || props.action?.label || props.title || (props.button ? 'Button' : null),
        value: props.actionValue || props.action?.value || props.action?.href || null,
    };

    return !Object.keys(action).length ? null : action;
}