import ReactPlayer from 'react-player'
import classNames from "classnames";
import _throttle from "lodash/throttle";
import _kebabCase from "lodash/kebabCase";
import {forwardRef} from "react";

const config = {
    controls: false,
    playing: true,
    muted: true,
    looping: true,
}

const Video = forwardRef((props, ref) => {
    return (
        <ReactPlayer url={props.url}
                     ref={ref}
                     id={props.id}
                     key={_kebabCase(props.url + props.controls + props.className + props.containerHeight)}
                     className={classNames(
                         "react-player  video",
                         props.backgroundVideo ? "background-video" : null,
                         props.className,
                         props.aspectRatio ? props.aspectRatio : "aspect-video",
                     )}
                     controls={props.controls ?? config.controls}
                     playing={props.playing ?? config.playing}
                     playsinline={true}
                     muted={props.muted ?? config.muted}
                     volume={props.volume}
                     loop={props.looping ?? config.looping}
                     height={props.height || 'auto'}
                     width={props.width || '100%'}
                     onReady={(player) => {
                         if (props.backgroundVideo) {
                             window.requestAnimationFrame(() => {
                                 const figureItOut = () => {
                                     const iframe = player.getInternalPlayer().g || player.getInternalPlayer().element;
                                     if (iframe) {
                                         const iframeParent = iframe.parentNode;
                                         const reactPlayer = iframeParent.parentNode;
                                         const reactPlayerRatio = reactPlayer.clientWidth / reactPlayer.clientHeight;
                                         const iframeRatio = iframe.clientWidth / iframe.clientHeight;

                                         if (iframeRatio > reactPlayerRatio) {
                                             iframe.style.height = '100%';
                                             iframe.style.width = 'auto';
                                             iframe.style.transform = "translateX(-50%)";
                                             iframe.style.left = "50%";
                                             iframe.style.position = "absolute";
                                             iframeParent.setAttribute('style', 'height:100% !important; width:auto')
                                         } else {
                                             iframe.style.height = '100%';
                                             iframe.style.width = '100%';
                                             iframe.style.transform = "";
                                             iframe.style.left = "";
                                             iframe.style.position = "";
                                             iframeParent.setAttribute('style', 'height:auto !important; width:auto')
                                         }

                                         setTimeout(() => {
                                             iframe.style.visibility = "visible";
                                         })
                                     }
                                 }

                                 figureItOut();

                                 // Handle window resize events
                                 window.addEventListener("resize", _throttle(figureItOut, 300))
                             })
                         }
                     }}

        />
    );
});

export default Video;