import {forwardRef, useEffect, useRef, useState} from 'react';
import Swiper, {Autoplay, Mousewheel, Navigation, Pagination, Thumbs} from 'swiper-web-element';
import classNames from "classnames";
import Action from "./action";
import {Image} from "./image";
import useCdn from "../hooks/cdn-hook";
import {Item} from "react-photoswipe-gallery";
import Video from "./video";
import PlayIcon from "@heroicons/react/solid/PlayIcon";

Swiper.use([Pagination, Autoplay, Navigation, Thumbs, Mousewheel])

const SwiperSlide = forwardRef(({item, index, ...props}, ref) => {
    const src = item.video ? useCdn(item.video?.url, 'video') : useCdn(item.imagesState?.__cx__?.key || item.image);
    return (
        <div className={classNames("swiper-slide overflow-hidden", props.swiperSlideClassName)}>
            {!!props.photoSwipe && !item.video
                ? (
                    <Item
                        original={useCdn(item.imagesState?.__cx__?.key || item.image)}
                        thumbnail={useCdn(item.imagesState?.__cx__?.key || item.image)}
                        width={global.innerWidth}
                        height={global.innerHeight}
                        title={item.caption}>
                        {({ref, open}) => {
                            return (
                                item.image ? (
                                    <div
                                        className={classNames("relative rounded-theme overflow-hidden h-full")}>
                                        <Image ref={ref}
                                               lazy={index === 0 ? 'eager' : null}
                                               id={item.imagesState?.__cx__?.id ? `image_${item.imagesState.__cx__.id}` : null}
                                               onClick={open}
                                               layout={!item.width ? "responsive" : "fixed"}
                                               width={item.width ? item.width : null}
                                               src={item.imagesState?.__cx__?.key || item.image}
                                               alt={item?.altText}
                                               className={classNames(" absolute object-center object-cover w-full h-full !rounded-none", props.aspectRatio, props.className)}
                                               style={props.style}
                                        />
                                    </div>
                                ) : null
                            )
                        }}
                    </Item>
                ) : (
                    <>
                        {item.image &&
                            <Image id={item.imagesState?.__cx__?.id ? `image_${item.imagesState.__cx__.id}` : null}
                                   lazy={index === 0 ? 'eager' : null}
                                   layout={!item.width ? "responsive" : "fixed"}
                                   width={item.width ? item.width : null}
                                   src={item.imagesState?.__cx__?.key || item.image}
                                   alt={item?.altText}
                                   className={classNames(" absolute object-center object-cover w-full h-full !rounded-none", props.aspectRatio, props.className)}
                                   style={props.style}
                            />}
                        {item.video &&
                            <Video url={src}
                                   ref={ref}
                                   {...item.video}
                                   muted={true}
                                   aspectRatio={props.aspectRatio ?? 'aspect-[4/3]'}
                                   height={'100%'}
                                   controls={(item.video?.controls !== undefined) ? item.video.controls : true}
                                   className={classNames("absolute object-center object-cover w-full h-full !rounded-none", props.aspectRatio, props.className)}/>
                        }
                        <Action {...item} title={null} button={false} defaultHref={'/'} className={"block"}>
                            <span className="absolute inset-0"/>
                        </Action>
                    </>
                )}
        </div>
    )
})

export const SwiperElement = (props) => {
    const defaultOptions = {
        direction: "horizontal",
        loop: true,
        observer: true,
        mousewheel: false,
        watchOverflow: true,
        threshold: 10,
        containerClassName: props.containerClassName || null,
        pagination: {
            el: `.swiper-pagination`,
            dynamicBullets: true,
            dynamicMainBullets: 2,
            type: 'bullets',
            clickable: true
        },

        ...props.values?.swiperOptions
    }

    if (defaultOptions.navigation) defaultOptions.navigation = {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    }

    const swiperContainerRef = useRef(null);
    const swiperThumbContainerRef = useRef(null);
    const swiperElRef = useRef(null);
    const swiperThumbElRef = useRef(null);
    const videoSlideRef = useRef(null);
    const autoplayValue = props?.values?.options?.autoplaySeconds * 1000;
    const [loaded, setLoaded] = useState(false);

    // Determines if autoplay is enabled and how long the duration is.
    defaultOptions.autoplay = props?.values?.options?.autoplay && autoplayValue > 0 ? {
        delay: autoplayValue,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
    } : false;

    // If we have one image only theres no need for them to interact with swiper elements
    defaultOptions.enabled = props?.values?.items?.length > 1;

    useEffect(() => {
        window.requestAnimationFrame(() => {
            if (props.thumbs) {
                swiperThumbElRef.current = new Swiper(swiperThumbContainerRef.current, {
                    ...props.thumbs.swiper,
                });
            }

            swiperElRef.current = new Swiper(swiperContainerRef.current, {
                ...defaultOptions,
                thumbs: {swiper: swiperThumbElRef.current}
            });

            swiperElRef.current.on('slideChange', function (swiper) {
                const activeVideo = swiper?.slides[swiper?.activeIndex]?.querySelector('video');
                if (activeVideo) {
                    activeVideo.muted = true;
                    activeVideo.setAttribute("muted", "");
                    activeVideo.play();
                }
                const player = videoSlideRef?.current?.player?.player?.player;
                if (player) swiper.activeIndex === 0
                    ? player.play()
                    : player.pause();
            });

            // Need a better way to do this as it recreates it everytime
            setLoaded(true)
        })

        return () => {
            // Remove on unmount
            if (swiperElRef.current) {
                swiperElRef.current.destroy();
                swiperElRef.current = null;
            }
            if (swiperThumbElRef.current) {
                swiperThumbElRef.current.destroy();
                swiperThumbElRef.current = null;
            }
        }
    }, [props?.values?.options?.autoplaySeconds, props?.values?.options?.autoplay, props?.values?.items, loaded]);

    useEffect(() => {
        if (props.currentSlideIndex && swiperElRef.current) swiperElRef.current.slideTo(props.currentSlideIndex);
    }, [props?.currentSlideIndex]);


    return (
        <>
            <div className={classNames(
                "swiper-container overflow-hidden w-full h-full",
                defaultOptions.containerClassName,
                loaded ? "opacity-100 transition-opacity" : "opacity-0",
                defaultOptions.enabled ? "cursor-grab active:cursor-grabbing" : null
            )} ref={swiperContainerRef}>
                <div className="swiper-wrapper">
                    {props.video &&
                        <SwiperSlide ref={videoSlideRef} {...props} item={{video: {url: props.video}}}/>
                    }
                    {props.values?.items?.map((item, i) => {
                        item.imagesState = item.imagesState || item.sourceState
                        item.image = item.image || item.source
                        return item?.options?.mediaType === 'video'
                            ? <SwiperSlide index={i} key={i} item={{...item, video: item?.video}}/>
                            : <SwiperSlide index={i} {...props} key={i} item={item}/>
                    })}
                </div>
                {defaultOptions.pagination && <div className={classNames("swiper-pagination py-1")}></div>}
                {defaultOptions.navigation && (
                    <>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                    </>
                )}
            </div>
            {props.thumbs && (
                <div className={classNames(
                    "swiper-container overflow-hidden h-full mt-4 cursor-grab",
                    loaded ? "opacity-100 transition-opacity" : "opacity-0",
                )} ref={swiperThumbContainerRef}>
                    <div className="swiper-wrapper">
                        {props.video &&
                            <div
                                className={classNames("swiper-slide relative overflow-hidden cursor-pointer", props.swiperSlideClassName)}>
                                <PlayIcon
                                    className={"absolute h-10 w-10 fill-white z-10 left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 opacity-80"}/>
                                <Video url={useCdn(props.video, 'video')}
                                       aspectRatio={props.aspectRatio ?? 'aspect-[4/3]'}
                                       width={100}
                                       playing={false}
                                       muted={true}
                                       style={props.style}
                                       className={classNames(" absolute object-center object-cover w-full h-full", props.aspectRatio, props.className)}/>
                            </div>
                        }
                        {props.thumbs.swiper?.values?.items?.map((item, i) => {
                            item.imagesState = item.imagesState || item.sourceState
                            item.image = item.image || item.source

                            return (
                                <div
                                    className={classNames("swiper-slide overflow-hidden cursor-pointer", props.swiperSlideClassName)}
                                    key={i}>
                                    <Image
                                        id={item.imagesState?.__cx__?.id ? `image_${item.imagesState.__cx__.id}` : null}
                                        key={i}
                                        width={100}
                                        src={item.imagesState?.__cx__?.key || item.image}
                                        alt={item?.altText}
                                        className={classNames(" absolute object-center object-cover w-full h-full", props.aspectRatio, props.className)}
                                        style={props.style}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    {defaultOptions.pagination && <div className={classNames("swiper-pagination")}></div>}
                </div>
            )}
        </>
    );
};