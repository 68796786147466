import classNames from 'classnames';

import {
    backgroundColour,
    height,
    textAlignment,
    imagePosition,
    flexDirectionBasedOnImagePosition,
    backgroundPattern,
    frameType, aspectRatio, setTitleDecorationByClass, setTitleDecorationByElement, objectFit,
} from "../../utils/block-utils";
import Block from '../block'
import {useEffect} from "react";
import Action from "../../components/action";
import {Image} from "../../components/image";

Design2.propTypes = {
    
};

Design2.defaultProps = {};

/**
 * Header with inline image
 * 
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Design2(props) {
    const flexDirection = flexDirectionBasedOnImagePosition(props);

    let radius = '0';
    
    useEffect(() => {
        radius = getComputedStyle(props.pageRef.current)?.getPropertyValue('--container-roundness');
    },[props.pageRef]);

    return (
        <Block {...props} className={classNames(props.className, backgroundPattern(props), backgroundColour(props.values?.options?.backgroundColour))}>
            <div className={classNames(
                "mx-auto max-w-7xl header section flex items-center",
                height(props),
                textAlignment(props),
                flexDirection
            )}>
                <div className={classNames(
                    flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : "w-full sm:w-3/5"
                )}>
                    {props.values?.title ? (
                        <>
                            <h1 className={classNames("relative rich-text", setTitleDecorationByClass(props.values?.options?.titleDecoration))} dangerouslySetInnerHTML={{__html: props.values.title}}/>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                    ) : null}
                    <Action className={"mt-6"} {...props.values} title={null} />
                </div>
                    {props.values?.image ? (
                        <div style={{borderRadius: radius === '0px' ? '0' : ''}}
                             className={classNames(
                                 "relative rounded-theme",
                                 flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : "w-full sm:w-3/5",
                                 imagePosition(props),
                                 frameType(props) === 'border' ? "border-8 border-base bg-base" : '',
                                 frameType(props) === 'circle' ? 'clip-circle' : '',
                                 frameType(props) === 'slant' ? 'clip-slant' : ''
                             )}>
                            <Image
                                id={props.values.imageState?.__cx__?.id ? `image_${props.values.imageState?.__cx__?.id}` : null}
                                alt={props?.values?.altText}
                                src={props.values.imageState?.__cx__?.key || props.values?.image}
                                className={classNames(
                                    " relative z-[1] w-full h-full object-center", 
                                    aspectRatio(props),
                                    objectFit(props.values?.options?.imagePresentation),
                                    frameType(props) === 'slant' ? '!rounded-none' : '',
                                    frameType(props) === 'border' ? "rounded-theme-half" : ''
                                )}
                            />
                        </div>

                    ) : null}
            </div>
        </Block>
    )
}