import Form from "@autocx/forms";
import classNames from "classnames";
import useDebounce from "../../hooks/use-debounce";
import {useContext, useMemo} from "react";
import {WebsiteContext} from "../../index";
import {scrollTop} from "./index";

export const toggleFilters = (value, name, activeFilters, setActiveFilters, remove, productsList) => {
    const newActiveFilters = {...activeFilters};

    if (remove) {
        if (Array.isArray(newActiveFilters[name])) {
            const index = newActiveFilters[name]?.indexOf(value);
            newActiveFilters[name].splice(index, 1)
            if (newActiveFilters[name]?.length === 0) delete newActiveFilters[name]
        } else {
            delete newActiveFilters[name]
        }
    } else {
        newActiveFilters[name] = value;
    }

    if (name !== "page" && newActiveFilters.page) delete newActiveFilters.page

    if (!newActiveFilters[name] || newActiveFilters[name]?.length === 0) delete newActiveFilters[name];

    buildParams(newActiveFilters);
    setActiveFilters(newActiveFilters)
    scrollTop(productsList.current, "instant");
}

export const clearFilters = (activeFilters, setActiveFilters) => {
    const newActiveFilters = {}
    if (activeFilters.sort) newActiveFilters.sort = [...activeFilters.sort];

    buildParams(newActiveFilters);
    setActiveFilters(newActiveFilters)
}

export const hasActiveFilters = (activeFilters) => {
    const newActiveFilters = {...activeFilters}
    if (newActiveFilters.sort) delete newActiveFilters.sort
    if (newActiveFilters.page) delete newActiveFilters.page
    const filterKeys = Object.keys(newActiveFilters);
    return filterKeys.length > 0;
}

export const buildParams = (filters, pushToHistory = true) => {
    let params = '';

    for (const filter in filters) {
        let filterParam = `${filter}=`;

        if (Array.isArray(filters[filter])) {
            filters[filter].forEach((value, i) => {
                filterParam += `${value};`
            })
        } else {
            filterParam += `${filters[filter]}`
        }

        params = params ? `${params}&${filterParam}` : filterParam;
    }

    const newUrl = params ? `${window.location.pathname}?${params}` : window.location.pathname;

    if (pushToHistory) window.history.pushState({}, '', newUrl)

    return `?${params}`;
}

export const buildFiltersFromParam = (setActiveFilters) => {
    const newActiveFilters = {};
    const params = window.location.href.split("?")[1];
    if (params) {
        const filters = params.split('&');
        filters.forEach(filter => {
            let key = filter.split('=')[0];
            let values = filter.split('=')[1];
            // Prevents special params from being filtered.
            if (['utm_', 'gclid', 'gad_', 'fbclid', 'fbc', 'fbp', 'gbraid', 'wbraid', 'msclkid', 'ttclid', 'li_fat_id', 'li_sugr', 'twclid', 'scid', '_pin_click', '__hssc', '__hstc', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].some(v => key.startsWith(v))) return;
            if (key === 'page' && (isNaN(+values) || +values < 1)) values = '1';
            values = values.split(';');
            newActiveFilters[key] = []
            values.length > 1
                ? values.forEach(v => {
                    if (v) newActiveFilters[key].push(decodeURI(v))
                })
                : newActiveFilters[key] = decodeURI(values[0])
        })
    }
    setActiveFilters(newActiveFilters)
}

export const buildESQueryBody = (activeFilters, size = 24, storeSettings) => {
    let criteria = {query: {bool: {must: []}}, size: size};
    let aggsFilters = {};

    for (const filter in activeFilters) {
        let bool;
        if (filter === 'sort') criteria.sort = {[activeFilters[filter][0]]: activeFilters[filter][1]}
        else if (filter === 'page') criteria.from = (activeFilters[filter] - 1) * size;
        else if (filter.includes('-min') || filter.includes('-max')) {
            let id = filter.split('-');
            const minMax = id.pop() === 'min' ? 'gte' : 'lte';
            bool = {must: {range: {[id.join('-')]: {[minMax]: activeFilters[filter]}}}};
        } else if (filter.includes('keyword-search')) bool = {
            should: {
                multi_match: {
                    query: activeFilters[filter],
                    type: 'cross_fields',
                    operator: "and",
                    fields: ["title", ...storeSettings?.fields?.search]
                }
            }
        };
        else if (filter.includes('discount')) bool = {must: {range: {discountedSellingPrice: {gte: 0}}}}
        else {
            bool = {should: [], minimum_should_match: 1}
            const name = (['make', 'family', 'variant'].includes(filter)) ? `${filter}.keyword` : filter;
            if (Array.isArray(activeFilters[filter])) {
                activeFilters[filter].forEach((value) => {
                    bool.should.push({match_phrase: {[name]: value}})
                })
            } else {
                bool.should.push({match_phrase: {[name]: activeFilters[filter]}})
            }
        }

        if (bool) {
            criteria.query.bool.must.push({bool})
            aggsFilters[filter] = {bool};
        }
    }

    return {criteria, aggsFilters}
}

const getStoreFields = (storeSettings, storeOptions, activeFilters) => {
    return storeSettings?.filters?.search || storeSettings?.filters?.price || storeSettings?.filters?.discount ? [{
        fields: [
            storeSettings?.filters?.search ? {
                label: "Search",
                type: "text",
                name: 'keyword-search',
                placeholder: "What are you looking for?",
                className: "!text-base",
                required: true,
            } : null,
            storeSettings?.filters?.price ? {
                label: "Price",
                type: "control-group",
                hideFieldLabels: true,
                fields: [
                    {
                        name: 'unitPrice-min',
                        type: 'currency',
                        hidePrefix: true,
                        className: "!text-base",
                        placeholder: 'Minimum',
                    }, {
                        name: 'unitPrice-max',
                        type: 'currency',
                        hidePrefix: true,
                        className: "!text-base",
                        placeholder: 'Maximum',
                    }
                ]
            } : null,
            storeSettings?.filters?.discount ? {
                label: "Discounted Only",
                name: "discount",
                type: "switch",
                small: true,
                flipLayout: true,
            } : null,
            storeSettings?.filters?.labels && storeOptions?.labels?.length > 0 ? {
                label: "Tags",
                name: "labels",
                type: 'checkbox-list',
                className: "sm:grid-cols-1 grid-cols-2 space-y-0",
                options: [...storeOptions.labels],
                value: activeFilters?.['labels']
            } : null
        ].filter(field => !!field)
    }] : []
}

const getFieldsets = (filters, activeFilters) => {
    const fieldsets = [];
    filters?.forEach((filter, i) => {
        const fieldset = {...filter, fields: [], collapsable: true, expanded: i === 0};
        filter.fields.forEach(field => {
                let filter;
                switch (field.type) {
                    case "colour-picker" :
                        filter = {
                            ...field,
                            name: field.id,
                            type: field.type,
                            className: "sm:grid-cols-1 grid-cols-2 space-y-0",
                            value: activeFilters[field.id]
                        };
                        break
                    case "combobox" :
                        filter = {
                            ...field,
                            name: field.id,
                            type: field.type,
                            canCreate: false,
                            required: true,
                            inputClassName: "!text-base",
                            placeholder: `Any ${field.label}`,
                            value: activeFilters[field.id]
                        };
                        break
                    case "number" :
                        filter = {
                            ...field,
                            type: "control-group",
                            hideFieldLabels: true,
                            fields: [
                                {
                                    ...field,
                                    name: `${field.id}-min`,
                                    id: `${field.id}-min`,
                                    type: 'number',
                                    min: 0,
                                    placeholder: 'Minimum',
                                    className: "!text-base",
                                    options: null,
                                }, {
                                    ...field,
                                    name: `${field.id}-max`,
                                    id: `${field.id}-max`,
                                    type: 'number',
                                    placeholder: 'Maximum',
                                    className: "!text-base",
                                    options: null,
                                }
                            ]
                        }
                        break
                    default:
                        filter = {
                            ...field,
                            name: field.id,
                            type: 'checkbox-list',
                            className: "sm:grid-cols-1 grid-cols-2 space-y-0",
                            value: activeFilters[field.id]
                        };
                }

                fieldset.fields.push(filter)
            }
        )
        fieldsets.push({divider: true}, fieldset)

        if (i === filters.length - 1) fieldsets.push({divider: true});
    });

    return fieldsets;
}

export default function Filters({
                                    filters,
                                    className,
                                    activeFilters,
                                    setActiveFilters,
                                    categories,
                                    productsList,
                                    storeOptions
                                }) {
    const context = useContext(WebsiteContext);
    const fieldset = getFieldsets(filters, activeFilters);

    const storeFields = useMemo(() => {
        return getStoreFields(context?.website?.store, storeOptions, activeFilters)
    }, [context?.website?.store, storeOptions, activeFilters]);

    const debouncedRequest = useDebounce((newActiveFilters) => {
        buildParams(newActiveFilters);
        scrollTop(productsList.current, "instant");
    }, 300);

    return (
        <div className={classNames(className)}>
            {categories.length > 0 && (
                <div className="pb-4">
                    <h6 className="text-base font-semibold text-gray-700 group-hover:text-link">Categories</h6>
                    <div className="space-y-1">
                        {categories.map(category => (
                                <div key={category.id}>
                                    <a href={category.uri + buildParams(activeFilters, false)} className={classNames(
                                        category.count ? "cursor-pointer" : "pointer-events-none",
                                        "font-medium !text-inherit"
                                    )}>
                                        {category.title}
                                        <span
                                            className="ml-1.5 rounded bg-gray-200 px-1 py-0.5 text-xs font-semibold tabular-nums text-gray-600">{category.count}</span>
                                    </a>
                                </div>
                            )
                        )}
                    </div>
                </div>
            )}
            <Form
                onFieldChange={(e, name, value) => {
                    const type = e?.target?.type;
                    if (type === 'number' || type === 'text') {
                        const newActiveFilters = {...activeFilters, [name]: value};
                        if (!newActiveFilters[name]) delete newActiveFilters[name];
                        if (newActiveFilters.page) delete newActiveFilters.page
                        setActiveFilters(newActiveFilters)
                        debouncedRequest(newActiveFilters);
                    } else {
                        toggleFilters(value, name, activeFilters, setActiveFilters, false, productsList)
                    }
                }}
                values={activeFilters}
                layout={"panel-style"}
                className={"h-full list-filters"}
                fieldsets={[...storeFields, ...fieldset]}
            />
        </div>
    )
}