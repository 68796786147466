import React from 'react';
import './suppressErrors';
import { hydrateRoot } from 'react-dom/client';
import App from './app';

const script = document.getElementById('view_state');
const props = JSON.parse(script.dataset.object);
const container = document.getElementById('vert-app').parentElement;

hydrateRoot(container, <App {...props} />);

script.remove();