import classNames from 'classnames';

import {
    backgroundColour,
    height,
    textAlignment,
    imagePosition,
    flexDirectionBasedOnImagePosition,
    backgroundPattern,
    parallax,
    setTitleDecorationByElement,
} from "../../utils/block-utils";
import Block from '../block'
import useCdnUrl from '../../hooks/cdn-hook';
import {useEffect} from "react";
import Action from "../../components/action";
import {Image} from "../../components/image";

Design4.propTypes = {};

Design4.defaultProps = {};

/**
 * Header with image overlap
 *
 * Extra styles defined in .header.overlap.section @ index.css because we can't do media queries in here
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Design4(props) {
    useEffect(() => {
        parallax("para", 8);
    }, []);
    
    return (
        <Block {...props}
               className={classNames(props.className, backgroundPattern(props), backgroundColour(props.values?.options?.backgroundColour))}>
            <div className={classNames(
                "mx-auto max-w-7xl header overlap section flex items-center",
                height(props),
                textAlignment(props),
                flexDirectionBasedOnImagePosition(props),
                props.values?.options?.imagePosition === 0 ? 'left' : 'right'
            )}>
                <div className={"para w-full sm:w-1/2"}>
                    <div>
                        <div className={classNames(
                            "overlap-panel container-padding rounded-theme",
                            props.values?.options?.backgroundColour < 4 ? 'section-dark' : 'section-grey'
                        )}>
                            {props.values?.title ? (
                                <>
                                    <h1 className={"rich-text"} dangerouslySetInnerHTML={{__html: props.values.title}}/>
                                    {props.values?.options?.titleDecoration &&
                                        setTitleDecorationByElement(props.values?.options?.titleDecoration)
                                    }
                                </>
                            ) : null}
                            {props.values?.text ? (
                                <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                            ) : null}
                            <Action className={"mt-6"} {...props.values} title={null}/>
                        </div>
                    </div>
                </div>
                <div className={classNames(
                    "w-full sm:w-1/2",
                    imagePosition(props)
                )}>
                    {props.values.imageState?.__cx__?.key || props.values?.image ? (
                        <Image className="media relative"
                             alt={props?.values?.altText}
                             id={props.values.imageState?.__cx__?.id ? `image_${props.values.imageState?.__cx__?.id}` : null}
                             src={props.values.imageState?.__cx__?.key || props.values?.image}/>
                    ) : null}
                </div>
            </div>
        </Block>
    )
}