import ControlGroup from "./control-group";
import React, {useContext} from "react";
import {FormContext} from "../../index";
import classNames from "classnames";

ActionInput.propTypes = {};

ActionInput.defaultProps = {
    onChange: () => {},
}

const getFields = (props, basePath, formContext) => {
    const blocks = formContext && formContext.selectedNode?.components?.length > 0
        ? formContext.selectedNode.components.map((c) => ({label: c?.values?.title || c?.label, value: c?.id}))
        : null

    const channelId = formContext?.hrefUriParams?.channelId || formContext?.websiteManagementContext?.channel?.code;
    const showingActionButtonPath = basePath ? `${basePath}.showingActionButton` : `showingActionButton`

    return [
        {
            label: props.label ? props.label : "Show Action Button",
            type: "switch",
            name: `${showingActionButtonPath}`,
            flipLayout: true
        },
        {
            label: "Button Label",
            type: "text",
            name: `${props.name}.label`,
            showsWhen: `${showingActionButtonPath} === true`
        },
        {
            label: "Action Type",
            type: "select",
            name: `${props.name}.type`,
            options: [
                channelId ? {label: "Open page", value: "page"} : null,
                {label: "Open link", value: "link"},
                {label: "Download file", value: "file"},
                blocks ? {label: "Scroll to block", value: "block"} : null,
                {label: "Open Product Page", value: "product"},
                {label: "View Product Checkout", value: "checkout"},
                {label: "View Product Category", value: "category"}
            ].filter(o => !!o),
            showsWhen: `${showingActionButtonPath} === true`,
            required: true,
        },
        {
            label: "Category",
            type: "combobox",
            name: `${props.name}.uniqueUri`,
            showsWhen: `${showingActionButtonPath} === true && ${props.name}.type === 'category'`,
            href: "/api/v2/categories?all=true",
            required: true,
            pageable: false,
            pageSize: 10000,
            canCreate: false,
            labelFieldPath: "category",
            valueFieldPath: "id",
            fuzzySearch: true,
            onLoad: "const allOptions = [];(function getOptions(options) {for (const o of options) {const option = o.original ?? o;allOptions.push({label: option?.category?.replace(\/\\\/\\\/\\\/\/g, ' > '), value: option.id});if(option.options?.length > 0) getOptions(option.options);}})(options);return allOptions;"
        },
        {
            label: "File",
            type: "document-uploader",
            description: "Maximumfile : 100MB",
            name: `${props.name}.value`,
            required: true,
            showsWhen: `${showingActionButtonPath} === true && ${props.name}.type === 'file'`
        },
        {
            label: "Page",
            type: "combobox",
            name: `${props.name}.uniqueUri`,
            showsWhen: `${showingActionButtonPath} === true && ${props.name}.type === 'page'`,
            href: `/api/v2/channels/${channelId}/search/pages`,
            required: true,
            pageable: true,
            fuzzySearch: true,
            canCreate: false
        },
        {
            label: "Block",
            type: "combobox",
            name: `${props.name}.value`,
            showsWhen: `${showingActionButtonPath} === true && ${props.name}.type === 'block'`,
            options: blocks,
            required: true,
            pageable: true,
            fuzzySearch: true,
            canCreate: false
        },
        {
            label: "Product",
            type: "combobox",
            name: `${props.name}.productUuid`,
            showsWhen: `${showingActionButtonPath} === true && ${props.name}.type === 'checkout' || ${props.name}.type === 'product' && ${showingActionButtonPath} === true`,
            href: "/api/v2/search/products",
            required: true,
            pageable: true,
            pageSize: 10000,
            canCreate: false,
            labelFieldPath: "title",
            valueFieldPath: "id"
        },
        {
            label: "Button URL",
            type: "text",
            name: `${props.name}.value`,
            showsWhen: `${showingActionButtonPath} === true && ${props.name}.type === 'link'`,
            required: true
        },
        {
            label: "Open in new tab",
            type: "switch",
            name: `${props.name}.target`,
            truthyValue: "_blank",
            falsyValue: "_self",
            showsWhen: `${showingActionButtonPath} === true && ${props.name}.type !== 'file'`,
            flipLayout: true,
            small: true
        },
    ]
}

export default function ActionInput(props) {
    const basePath = props.name.split('.').slice(0, -1).join('.');
    const formContext = useContext(FormContext);
    const fields = getFields(props, basePath, formContext);
    const showEdit = props.editing && !props.system;

    return (
        <div>
            {showEdit ? (
                <div className={classNames("block w-full")}>
                <span
                    className={"text-xs font-medium text-primary-500 leading-5 cursor-pointer hover:underline"}
                    onClick={props.onEditFieldSettings}
                >Edit</span>
                </div>
            ) : null}
            <ControlGroup {...props} className={"!space-y-5 !block"} fields={fields}/>
        </div>
    )

}
