import {useContext, useEffect, useRef, useState} from "react";
import {FocusedImage} from "image-focus";
import classNames from "classnames";
import useCdnUrl from "../hooks/cdn-hook";
import {WebsiteContext} from "../index";

export function BgImage(props) {
    if (!props.values || !props.values.image) return null;

    const imageRef = useRef(null);
    const focus = props.values.imageState?.focus ?? {x: 0, y: 0};
    const [visible, setVisible] = useState(false);
    const context = useContext(WebsiteContext);
    const imageSrc = useCdnUrl(props.values.imageState?.__cx__?.key || props.values.image);

    // Lazy-load the image using IntersectionObserver.
    useEffect(() => {
        if (!imageRef.current) return;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                    observer.disconnect();
                }
            },
            {threshold: context?.picker ? 0 : 0.1}
        );
        observer.observe(imageRef.current);
        return () => observer.disconnect();
    }, []);

    // When the image is visible, set up the FocusedImage and handle onLoad.
    useEffect(() => {
        if (!imageRef.current || !focus) return;
        let focusImage = new FocusedImage(imageRef.current, {
            updateOnContainerResize: true,
            focus: focus,
        });

        const onLoad = () => {
            if (imageRef.current) {
                imageRef.current.removeEventListener("load", onLoad);
                imageRef.current.classList.remove("invisible");
            }
        };

        imageRef.current.addEventListener("load", onLoad);

        return () => {
            if (imageRef.current) {
                imageRef.current.removeEventListener("load", onLoad);
            }
            focusImage.stopListening();
            focusImage = null;
        };
    }, [visible, focus]);

    return (
        <div className="w-full h-full !absolute overflow-hidden">
            {props.hasOverlay && (
                <div className="!bg-black/[.3] absolute z-[1] w-full min-h-screen"></div>
            )}
            <img
                ref={imageRef}
                id={
                    props.values.imageState?.__cx__?.id
                        ? `image_${props.values.imageState.__cx__.id}`
                        : null
                }
                // Only set the src if visible; otherwise, it stays undefined
                src={
                    visible
                        ? imageSrc
                        : undefined
                }
                alt={props.values.altText || ""}
                className={classNames(
                    "absolute object-center object-cover invisible max-w-none",
                    props.className
                )}
                style={props.style}
            />
        </div>
    );
}