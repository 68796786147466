import classNames from "classnames";
import _isArray from 'lodash/isArray';
import Currency from "@autocx/forms/src/controls/currency";
import {Disclosure} from '@headlessui/react'
import AnimateHeight from "react-animate-height";
import {Fragment, useContext, useMemo, useState} from "react";
import {WebsiteContext} from "../../index";
import {aspectRatio, backgroundColour, backgroundPattern} from "../../utils/block-utils";
import Block from "../block";
import {Accordion, getProductFields, ProductCtaContainer} from "./index";
import {EnquireButton, EnquiryActionBanner, EnquiryPurchaseMethod} from "./enquiry-purchase-method";
import {BuyNowActionBanner, BuyNowPurchaseMethod} from "./buy-now-purchase-method";
import useDetectKeyboardOpen from "../../hooks/useDetectKeyboardOpen";
import {SwiperElement} from "../../components/swiper-element";
import {Gallery} from "react-photoswipe-gallery";
import WebsiteFieldRenderer from "../../components/website-field-renderer";
import PricingFields from "./components/pricing-fields";

const galleryOptions = {
    shareEl: false,
    showHideOpacity: true,
    maxSpreadZoom: 4,
    fullscreenEl: false,
    getDoubleTapZoom: function (isMouseClick, item) {
        if (isMouseClick) {
            return 2
        } else {
            return item.initialZoomLevel < 0.7 ? 1 : 4;
        }
    }
}

export default function Design1(props) {
    const context = useContext(WebsiteContext)
    const [currentSlide, setCurrentSlide] = useState();
    const hasImages = _isArray(props.values.images) && props.values.images.length > 0
    const imageAspectRatio = {values: {options: {imageAspectRatio: context?.website?.store?.productCardAspectRatio}}}
    const isKeyboardOpen = useDetectKeyboardOpen();
    const productFields = useMemo(() => {
        return getProductFields(props?.values?.productTemplate?.userDefinedFieldsets)
    }, [props?.values?.productTemplate?.userDefinedFieldsets])
    const flattendedProductFields = props?.values?.productTemplate?.userDefinedFieldsets?.reduce((acc, cur) => acc.concat(cur.fields || []), []);
    
    const items = useMemo(() => {
        const images = !_isArray(props.values.images) ? [] : props.values.images;
        const imagesState = !_isArray(props.values.imagesState) ? [] : props.values.imagesState;

        const items = images.map((image, i) => {
            const obj = {image};
            obj.imagesState = imagesState[i] ? {...imagesState[i]} : {};
            obj.width = 720;
            return obj;
        });

        // If the images array is empty, add an empty image object to the items array as the placeholder
        if (items.length === 0) {
            items.push({
                image: null,
                imagesState: null,
                width: 720
            });
        }

        return items;
    }, [props?.values?.images, props?.values?.imagesState])

    const swiperProps = {
        video: props.values?.video,
        // only enable photoSwipe if there's actually images
        photoSwipe: hasImages,
        currentSlideIndex: currentSlide,
        containerClassName: "rounded-theme",
        values: {
            items,
            swiperOptions: {
                spaceBetween: 16,
                pagination: false,
                navigation: true,
                loop: false
            }
        },
        swiperSlideClassName: aspectRatio(imageAspectRatio),
        ...(hasImages && {
            thumbs: {
                swiper: {
                    direction: "horizontal",
                    observer: true,
                    mousewheel: true,
                    spaceBetween: 8,
                    slidesPerView: 8.5,
                    freeMode: true,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    watchOverflow: true,
                    threshold: 10,
                    values: {
                        items,
                    },
                }
            }
        })
    }

    const onOpen = (pswpInstance) => {
        pswpInstance.listen('beforeChange', () => {
            setCurrentSlide(pswpInstance.getCurrentIndex())
        });
    }

    return (
        <Gallery options={galleryOptions} onOpen={onOpen}>
            <Block {...props}
                   className={classNames(props.className, !props.values.options?.inset ? backgroundPattern(props) : null, !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
                   }>
                <div className={classNames("mx-auto max-w-7xl section flex flex-col items-center")}>
                    <h1 className="!mb-1 !text-2xl self-start block sm:hidden rich-text" dangerouslySetInnerHTML={{__html: props.values.title}}/>
                    <div
                        className={classNames("flex-col gap-y-6 sm:w-full sm:grid sm:grid-cols-5 sm:gap-x-8 sm:items-start w-full")}>
                        <div
                            className={"col-span-3 flex-row sm:sticky sm:top-[calc(var(--site-nav-height)+16px)] mb-4"}>
                            <SwiperElement {...swiperProps} />
                        </div>
                        <div className="text-left col-span-2">
                            <div className={""}>
                                <h1 className="!mb-0 !text-2xl hidden sm:block rich-text" dangerouslySetInnerHTML={{__html: props.values.title}}/>
                                {!props?.values?.productTemplate?.hidePriceOnWebsite && <>
                                    {props.values.discountedSellingPrice ? (
                                        <span className={classNames("text-2xl font-semibold")}>
                                            <Currency.DisplayValue currency={context.currency}
                                                                   value={props.values.discountedSellingPrice}
                                                                   emptyPlaceholder={""}/>
                                         </span>
                                    ) : null}
                                    <span
                                        className={classNames("", props.values.discountedSellingPrice ? "text-lg ml-2 opacity-80 line-through" : "text-2xl font-semibold")}>
                                        <Currency.DisplayValue currency={context.currency}
                                                               value={props.values.sellingPrice || ""}
                                                               emptyPlaceholder={"Contact Us"}/>
                                    </span>
                                    {props.values.sellingPriceDisclaimer ? (
                                        <div className={"text-gray-700 text-sm"}>
                                            {props.values.sellingPriceDisclaimer}
                                        </div>
                                    ) : null}
                                </>}
                                {props?.values?.productTemplate?.pricingFields && props?.values?.productTemplate?.pricingFields.length > 0 ? (
                                    <>
                                        <hr className="mt-4 mb-2 mx-0 w-full"/>
                                        <PricingFields
                                            pricingFields={props.values.productTemplate.pricingFields}
                                            productFields={flattendedProductFields}
                                            productValues={props.values}
                                        />
                                    </>
                                ) : null}
                                <div className={"hidden sm:block"}>
                                    {props.values.productTemplate?.purchaseMethod === 'buy-now' ? (
                                        <BuyNowPurchaseMethod {...props} />
                                    ) : null}
                                    {props.values.productTemplate?.purchaseMethod === 'enquiry' ? (
                                        <EnquireButton className={"mt-4"}/>
                                    ) : null}
                                </div>
                            </div>
                            {props.values.description && (
                                <div className={"rich-text mt-4"}
                                     dangerouslySetInnerHTML={{__html: props.values.description}}/>
                            )}
                            {productFields && <hr className={"mt-4"}/>}
                            {productFields?.map((fieldset, i) => {
                                if (props?.values?.productTemplateUuid?.includes('car') && fieldset?.id === 'features') {
                                    return (
                                        <Disclosure key={fieldset.id} id={fieldset.id} as="div"
                                                    className={"flex flex-col border-opacity-25"}>
                                            {({open}) => (
                                                <>
                                                    <Disclosure.Button
                                                        className="w-full flex justify-between items-start pt-5">
                                                        <h4 className={classNames("!text-base")}>
                                                            {fieldset.label}
                                                        </h4>
                                                        <span className="ml-6 h-7 flex items-center">
                                                            <span className={classNames(
                                                                "border-current h-6 w-6",
                                                                open ? "chevron--up" : "chevron--down"
                                                            )}>
                                                                <svg width="24" height="24" version="1.1"
                                                                     viewBox="0 0 100 100"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     preserveAspectRatio="xMidYMid"
                                                                     stroke="currentColor">
                                                                    <g className="chevron__container">
                                                                        <line className="chevron__line1" x1="10"
                                                                              y1="50" x2="50" y2="50"/><line
                                                                        className="chevron__line2" x1="90" y1="50"
                                                                        x2="50" y2="50"/>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            </span>
                                                    </Disclosure.Button>
                                                    <AnimateHeight
                                                        id={fieldset.id}
                                                        height={open ? 'auto' : 0}
                                                        animateOpacity={true}>
                                                        <Disclosure.Panel as="div" static
                                                                          className={classNames("mb-8 -mt-1.5 rich-text")}>
                                                            {fieldset.fields?.map((field, i) => (
                                                                <div key={field.id + i}
                                                                     className="pt-2 whitespace-pre-line">
                                                                    {field.prefix} {props.values[field.id] || '—'} {field.suffix}
                                                                </div>
                                                            ))}
                                                        </Disclosure.Panel>
                                                    </AnimateHeight>
                                                </>
                                            )}
                                        </Disclosure>
                                    )
                                }
                                return (
                                    <Fragment key={fieldset.id}>
                                        <Disclosure id={fieldset.id} as="div"
                                                    className={"flex flex-col border-opacity-25"}>
                                            {({open}) => (
                                                <>
                                                    <Disclosure.Button
                                                        className="w-full flex justify-between items-start pt-2.5">
                                                        <h4 className={classNames("!text-base")}>
                                                            {fieldset.label}
                                                        </h4>
                                                        <span className="ml-6 h-7 flex items-center">
                                                                <span className={classNames(
                                                                    "border-current h-6 w-6",
                                                                    open ? "chevron--up" : "chevron--down"
                                                                )}>
                                                                    <svg width="24" height="24" version="1.1"
                                                                         viewBox="0 0 100 100"
                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                         preserveAspectRatio="xMidYMid"
                                                                         stroke="currentColor">
                                                                        <g className="chevron__container">
                                                                            <line className="chevron__line1" x1="10"
                                                                                  y1="50" x2="50" y2="50"/><line
                                                                            className="chevron__line2" x1="90" y1="50"
                                                                            x2="50" y2="50"/>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                </span>
                                                    </Disclosure.Button>
                                                    <AnimateHeight
                                                        id={fieldset.id}
                                                        height={open ? 'auto' : 0}
                                                        animateOpacity={true}>
                                                        <Disclosure.Panel as="div" static
                                                                          className={classNames("mb-8 -mt-1.5 rich-text")}>
                                                            {fieldset.fields?.map((field, i) => (
                                                                <div key={field.id + i}
                                                                     className="grid grid-cols-2 gap-x-4 items-start mb-1">
                                                                    <WebsiteFieldRenderer
                                                                        fields={[field]}
                                                                        values={props.values}/>
                                                                </div>
                                                            ))}
                                                        </Disclosure.Panel>
                                                    </AnimateHeight>
                                                </>
                                            )}
                                        </Disclosure>
                                        {(productFields.length - 1) !== i && <hr/>}
                                    </Fragment>
                                )
                            })}
                            {productFields && <hr/>}

                            {context?.policies ? (
                                <div className={"mt-8 border flex flex-col px-3 rounded-theme"}>
                                    {Object.keys(context.policies).map((key, i) => (
                                        <Fragment key={key}>
                                            {context.policies[key] ?
                                                <Accordion
                                                    className={classNames(Object.keys(context?.policies).length > 1 && i === 1 ? "border-t" : null, "text-gray-700 !py-1")}
                                                    title={key.charAt(0).toUpperCase() + key.slice(1)}
                                                    text={context.policies[key]}
                                                />
                                                : null}
                                        </Fragment>
                                    ))}
                                </div>
                            ) : null}
                            {context?.website?.store?.productFootnote ? (
                                <div className={"mt-6 text-xs text-gray-600 whitespace-pre-line"}>
                                    {context?.website?.store?.productFootnote}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {props.values.productTemplate?.purchaseMethod === 'enquiry' ? (
                        <>
                            <hr className={"w-full mt-4"}/>
                            <div id="purchase-enquiry" className={"mt-4 max-w-md w-full"}>
                                <h2 className={"!text-2xl text-center"}>Enquire</h2>
                                <div
                                    className={"rounded-theme border border-gray-300 p-6 section-grey sm:shadow-lg"}>
                                    <EnquiryPurchaseMethod {...props} />
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
                {/* Mobile purchase details and method sticky banner */}
                <ProductCtaContainer
                    className={classNames(
                        "sticky", isKeyboardOpen ? "-bottom-20" : "bottom-0"
                    )}
                >
                    {props.values.productTemplate?.purchaseMethod === 'enquiry' ? (
                        <EnquiryActionBanner {...props} context={context}/>
                    ) : null}
                    {props.values.productTemplate?.purchaseMethod === 'buy-now' ? (
                        <BuyNowActionBanner {...props} context={context}/>
                    ) : null}
                </ProductCtaContainer>
            </Block>
        </Gallery>
    )
}