import classNames from "classnames";
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';
import Block from '../block'
import {
    aspectRatio,
    backgroundColour,
    backgroundPattern, frameType,
    setTitleDecorationByClass,
    setTitleDecorationByElement,
    textAlignment
} from "../../utils/block-utils";
import {listColumns} from "../list-of-items-block/design-1";
import Currency from "@autocx/forms/src/controls/currency";
import {useContext} from "react";
import {WebsiteContext} from "../../index";
import Action from "../../components/action";
import {kebabCase} from "lodash";
import {Image} from "../../components/image";
import ProductCard from "../product-card";

function ProductsBlockItem({item, ...props}) {
    const context = useContext(WebsiteContext)
    const image = item.images?.length ? item.images[0] : null;
    const imageState = item.imagesState?.length ? item.imagesState[0] : null;

    return (
        <div
            className={"py-4 w-64 inline-flex flex-col overflow-hidden snap-start lg:w-auto hover:cursor-pointer"}>
            <div className="relative">
                {image ? (
                    <div className={classNames(
                        "w-full overflow-hidden relative",
                        aspectRatio(props),
                        frameType(props) === 'circle' ? 'clip-circle' : '',
                        frameType(props) === 'slant' ? 'clip-slant !rounded-none' : '',
                    )}>
                        <Image id={imageState?.__cx__?.id ? `image_${imageState?.__cx__?.id}` : null}
                               src={imageState?.__cx__?.key || image}
                               alt={item?.altText}
                               className={classNames(
                                   " w-full h-full object-center object-cover rounded-theme",
                                   item.action ? 'group-hover:opacity-75' : '',
                                   frameType(props) === 'slant' ? '!rounded-none' : '',
                               )}
                        />
                    </div>
                ) : null}
                <div className="text-left mt-4">
                    <h6 className="!mb-1">
                        <Action showingActionButton={true} action={{
                            type: 'link',
                            value: `/product/${item.productId}/${kebabCase(item.title)}`
                        }} title={null} button={false} defaultHref={'#'}>
                            <span className="absolute inset-0"/>
                        </Action>
                        <span className="font-bold text-lg">{item.title}</span>
                    </h6>
                    <div className="mt-1">
                        <span className={classNames(item.discountedPrice ? "line-through" : null)}>
                            <Currency.DisplayValue currency={context.currency} value={item.price}
                                                   emptyPlaceholder={""}/>
                        </span>
                        {item.discountedPrice ? (
                            <span className="ml-2 text-red-600">
                                <Currency.DisplayValue currency={context.currency} value={item.discountedPrice}
                                                       emptyPlaceholder={""}/>
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function Design1({actions, onAction, onSelect, selectedBlock, ...props}) {
    const context = useContext(WebsiteContext)
    let items = _get(props, 'values.items', []) || [];
    if (_isArray(items)) {
        items = items.filter(i => !i.disabled);
    }

    return (
        <Block
            {...props}
            actions={actions}
            onAction={onAction}
            onSelect={onSelect}
            selectedBlock={selectedBlock}
            className={classNames(
                props.className,
                !props.values.options?.inset ? backgroundPattern(props) : null,
                !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
            }>
            <div className={classNames("mx-auto max-w-7xl section flex flex-col items-center",
                textAlignment(props),
                props.values.options?.inset ? 'rounded-theme' : null,
                props.values.options?.inset ? backgroundPattern(props) : null,
                props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null
            )}>
                <div className={"w-full"}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative rich-text", setTitleDecorationByClass(props.values?.options?.titleDecoration))} dangerouslySetInnerHTML={{__html: props.values.title}}/>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                    ) : null}
                </div>
                <div className="relative w-full overflow-x-auto snap-mandatory snap-x">
                    <div className={classNames(
                        "mb-4 lg:space-x-0 lg:grid lg:gap-x-8 lg:gap-y-12 text-left",
                        props.values.options?.listItemsMobileDisplay === "stack" ? "block space-y-6 sm:space-y-0" : "inline-flex space-x-4",
                        listColumns(props)
                    )}>
                        {items.map((item) => {
                            const product = item._source;
                            
                            return (
                                <div key={product.uuid} className={classNames(
                                    "flex", 
                                    props.values.options?.listItemsMobileDisplay === "stack" ? "w-full" : "w-72 sm:w-full"
                                )}>
                                <ProductCard product={product}
                                                 settings={{design: product?.design}}
                                                 values={{options: {imageAspectRatio: context?.website?.store?.productCardAspectRatio}}}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Action className={"mt-6"} {...props.values} title={null} />
            </div>
        </Block>
    )
}