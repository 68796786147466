import classNames from "classnames";
import Block from '../block'
import {
    backgroundColour,
    backgroundPattern,
    flexDirectionBasedOnImagePosition,
    imagePosition,
    marginForTextAlignment,
    setTitleDecorationByClass,
    setTitleDecorationByElement,
    textAlignment
} from "../../utils/block-utils";
import {useContext, useEffect, useRef, useState} from "react";
import {Tab} from '@headlessui/react'
import Form from "@autocx/forms";
import {WebsiteContext} from "../../index";
import {DEFAULT_PRODUCT_FIELDS, getUserDefinedFields} from "@autocx/forms/src/controls/category-field";
import axios from "axios";
import useDebounce from "../../hooks/use-debounce";
import {buildESQueryBody, buildParams} from "../products-list-block/filters";
import {getCurrencyLabel, getCurrencyValue} from "@autocx/forms/src/controls/currency";

const FilterTabs = ({
                        filters,
                        values,
                        activeFilters,
                        setActiveFilters,
                        templateFields,
                        action,
                        categories,
                        activeTab,
                        setActiveTab
                    }) => {
    const context = useContext(WebsiteContext);
    const [underlineStyle, setUnderlineStyle] = useState({});
    const activeTabRef = useRef(null);

    useEffect(() => {
        if (activeTabRef.current) {
            const tab = activeTabRef.current;
            const width = tab.offsetWidth - 8;
            const left = tab.offsetLeft + 4;
            setUnderlineStyle({width, transform: `translateX(${left}px)`, opacity: 1});
        }
    }, [categories]);

    const handleClick = (e) => {
        setActiveFilters([])
        const tab = e.currentTarget;
        const width = tab.offsetWidth - 8;
        const left = tab.offsetLeft + 4;
        setUnderlineStyle({width, transform: `translateX(${left}px)`});
    };

    return (
        <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
            {filters.length > 1 &&
                <Tab.List className="flex items-center justify-center relative mb-4 w-fit m-auto">
                    {filters.map((category, id) => (
                        <Tab key={category.categoryUuid + id} as="button" onClick={handleClick}
                             className={"outline-none"}>
                            {({selected}) => (
                                <div className="relative w-full px-2.5 mb-2 text-base font-semibold"
                                     ref={selected ? activeTabRef : null}>
                                    {category?.customLabel || categories[category.categoryUuid]?.title}
                                </div>
                            )}
                        </Tab>
                    ))}
                    <div
                        className="underline absolute bottom-0 rounded-theme left-0 h-1 transition-[transform,width,opacity] duration-200"
                        style={underlineStyle}></div>
                </Tab.List>
            }
            <Tab.Panels>
                {filters.map((filter, idx) => {
                    const filterFields = filter?.fields?.length > 0 ? filter?.fields : [{id: "keyword-search"}];
                    const fields = filterFields?.map((f) => {
                        let field = templateFields?.find((field) => field.value === f.id);
                        const options = values?.[field?.value];
                        
                        if (!field) return null;

                        switch (field.type) {
                            case "currency" :
                                return {
                                    type: "control-group",
                                    className: "gap-[1px] !space-y-0",
                                    hideFieldLabels: true,
                                    fields: [
                                        {
                                            id: `${field.value}-min`,
                                            name: `${field.value}-min`,
                                            type: f.priceOptions ? 'select' : 'currency',
                                            hidePrefix: true,
                                            className: "!text-base",
                                            placeholder: `Min ${field.label}`,
                                            options: f.priceOptions ? f.priceOptions.split(',')?.map(o => ({
                                                value: getCurrencyValue(+o, false, true),
                                                label: getCurrencyLabel(+o, context.currency)
                                            })) : [],
                                        }, {
                                            id: `${field.value}-max`,
                                            name: `${field.value}-max`,
                                            type: f.priceOptions ? 'select' : 'currency',
                                            hidePrefix: true,
                                            className: "!text-base",
                                            placeholder: `Max ${field.label}`,
                                            options: f.priceOptions ? f.priceOptions.split(',')?.map(o => ({
                                                value: getCurrencyValue(+o, false, true),
                                                label: getCurrencyLabel(+o, context.currency)
                                            })) : [],
                                        }
                                    ]
                                }
                            case "number" :
                                return {
                                    type: "control-group",
                                    className: "gap-[1px] !space-y-0",
                                    hideFieldLabels: true,
                                    fields: [
                                        {
                                            ...field,
                                            name: `${field.value}-min`,
                                            id: `${field.value}-min`,
                                            type: 'number',
                                            min: 0,
                                            placeholder: `Min ${field.label}`,
                                            className: "!text-base",
                                            options: null,
                                        }, {
                                            ...field,
                                            name: `${field.value}-max`,
                                            id: `${field.value}-max`,
                                            type: 'number',
                                            placeholder: `Max ${field.label}`,
                                            className: "!text-base",
                                            options: null,
                                        }
                                    ]
                                }
                            case "keyword-search" :
                                return {
                                    id: field.value,
                                    name: field.value,
                                    type: "text",
                                    placeholder: "What are you looking for?",
                                    className: "!text-base",
                                }
                            default:
                                return {
                                    id: field.value,
                                    name: field.value,
                                    type: filter?.disableCombobox ? 'select' : 'combobox',
                                    placeholder: `Any ${field.label}`,
                                    label: field.label,
                                    canCreate: false,
                                    options
                                }
                        }
                    }).filter(f => !!f);

                    return (
                        <Tab.Panel key={idx + filter.categoryUuid}>
                            <Form
                                onFieldChange={(e, name, value, values) => {
                                    const newValues = {};
                                    for (const [key, value] of Object.entries(values)) {
                                        if (values[key] && !!values[key]) newValues[key] = value;
                                    }
                                    setActiveFilters(newValues)
                                }}
                                values={activeFilters}
                                layout={"product-search-block"}
                                className={classNames(
                                    context.builder ? 'pointer-events-none' : null,
                                    "!space-y-0 flex flex-col gap-[1px] justify-center items-center sm:flex-row sm:gap-0"
                                )}
                                actions={[
                                    {
                                        label: action?.label ?? "Search",
                                        className: "button !px-8 !text-base shrink-0",
                                        onClick: () => {
                                            const filters = activeFilters;
                                            delete filters.userDefinedFieldsets;
                                            window.location.href = categories[filter.categoryUuid]?.uri + buildParams(filters, false);
                                        }
                                    }
                                ]}
                                fieldsets={[{fields}]}
                            />
                        </Tab.Panel>
                    )
                })}
            </Tab.Panels>
        </Tab.Group>
    );
};

export default function Design1(props) {
    const context = useContext(WebsiteContext)
    const [activeFilters, setActiveFilters] = useState({});
    const [categories, setCategories] = useState();
    const [filters, setFilters] = useState([]);
    const [templateFields, setTemplateFields] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [values, setValues] = useState();

    const fetchData = (cancelToken) => {
        Promise.all([
            axios.get('/api/v2/productTemplates', {cancelToken: cancelToken.token}),
            axios.get('/api/v2/categories?all=true', {cancelToken: cancelToken.token}),
        ])
            .then(([productTemplates, categories]) => {
                const allOptions = {};
                const getOptions = (options) => {
                    for (const o of options) {
                        const option = o.original ?? o;
                        allOptions[option.id] = {title: option?.category?.split('///').pop(), uri: option.uri};
                        if (option.options?.length > 0) getOptions(option.options);
                    }
                }
                const userDefinedFieldsets = productTemplates.data.reduce((result, template) => {
                    result.push(...template.userDefinedFieldsets)
                    return result;
                }, []);
                getOptions(categories.data);
                setTemplateFields([...DEFAULT_PRODUCT_FIELDS, ...getUserDefinedFields(userDefinedFieldsets)])
                setCategories(allOptions);
                if (props.values?.filters.length <= 0) setFilters([{
                    categoryUuid: Object.keys(allOptions)?.[0],
                    fields: [{id: "keyword-search"}]
                }])
            })
            .catch((err) => {
                if (!axios.isCancel(err)) console.log(err);
            })
    }

    const search = useDebounce((cancelToken) => {
        const {criteria, aggsFilters} = buildESQueryBody(activeFilters, 1000, context?.website?.store);

        let data = {
            body: criteria,
            categoryUuid: props.values?.filters?.[activeTab]?.categoryUuid,
            aggsFilters,
            activeFilters
        };

        axios
            .post('/products/search', data, {cancelToken: cancelToken.token})
            .then(({data}) => {
                setValues(data.values)
            })
            .catch((err) => {
                if (err.message !== 'canceled') console.log(err)
            });
    }, 300, axios.CancelToken.source());

    useEffect(() => {
        search();
    }, [activeFilters, activeTab, categories])

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        fetchData(cancelToken)
        return () => cancelToken.cancel();
    }, [])

    const flexDirection = flexDirectionBasedOnImagePosition(props);

    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values.options?.inset ? backgroundPattern(props) : null,
                   !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <div className={classNames(
                "mx-auto max-w-7xl section flex items-center",
                textAlignment(props),
                flexDirection,
                props.values.options?.inset ? 'rounded-theme' : null,
                props.values.options?.inset ? backgroundPattern(props) : null,
                props.values.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
            )}>
                <div className={classNames(
                    flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : "w-full sm:w-3/5")}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative rich-text", setTitleDecorationByClass(props.values?.options?.titleDecoration))} dangerouslySetInnerHTML={{__html: props.values.title}}/>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                             className={classNames("rich-text", marginForTextAlignment(textAlignment(props)))}
                        />
                    ) : null}
                </div>
                <div className={classNames(
                    "relative",
                    flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : "w-full sm:w-9/12",
                    imagePosition(props)
                )}>
                    {!categories
                        ? <>
                            {props.values?.filters.length > 1 && <div className={"w-full h-[48px]"}/>}
                            <div className={"w-full bg-white h-[44px] rounded-theme"}/>
                        </>
                        : <FilterTabs filters={props.values?.filters?.length > 0 ? props.values?.filters : filters}
                                      values={values}
                                      activeFilters={activeFilters}
                                      setActiveFilters={setActiveFilters}
                                      templateFields={templateFields}
                                      action={props.values?.action}
                                      setActiveTab={setActiveTab}
                                      activeTab={activeTab}
                                      categories={categories}
                        />}
                </div>
            </div>
        </Block>
    )

}