import List from "./list";
import classNames from "classnames";
import {useContext, useEffect, useRef, useState} from "react";
import {nanoid} from "nanoid/non-secure";
import useCdn from "../../../websites/src/hooks/cdn-hook";
import {Image} from "../../../websites/src/components/image";
import {Gallery as PhotoswipeGallery, Item} from 'react-photoswipe-gallery'
import {WebsiteContext} from "../../../websites";

const getSettings = (props) => {
    const categories = props.categories?.map(c => ({label: c.title, value: c.id}))

    return {
        sortable: true,
        labelFieldPath: "title",
        fieldsets: [
            {
                fields: [
                    categories?.length === 1 ? {
                        name: "category",
                        type: "hidden",
                        value: categories[0]
                    } : null,
                    categories?.length > 1 ? {
                        label: "Category",
                        name: "category",
                        type: "combobox",
                        canCreate: false,
                        options: categories,
                    } : null,
                    {
                        label: "Title",
                        type: "text",
                        name: "title",
                        required: true
                    },
                    {
                        label: "Text",
                        type: "rich-text",
                        name: "text"
                    },
                    {
                        label: "Image",
                        type: "image-uploader",
                        name: "image",
                    },
                    {
                        label: "Describe Image (Alt Text)",
                        description: "Text that is shown when an image doesn't load and also aids vision-impaired visitors and SEO",
                        type: "text",
                        name: "altText",
                        showsWhen: "image !== '' && image !== undefined"
                    },
                ].filter(f => !!f)
            }
        ]
    }
}

const galleryOptions = {
    shareEl: false,
    showHideOpacity: true,
    maxSpreadZoom: 4,
    fullscreenEl: false,
    getDoubleTapZoom: function (isMouseClick, item) {
        if (isMouseClick) {
            return 2
        } else {
            return item.initialZoomLevel < 0.7 ? 1 : 4;
        }
    }
}
const allId = nanoid();

Gallery.WebsiteProductDisplayView = function (props) {
    const context = useContext(WebsiteContext);
    const allCategory = {[allId]: {name: "All", id: allId, items: []}};

    const tabs = props?.categories ? props.categories.reduce(function (result, c) {
        result[c.id] = {name: c.title, id: c.id, items: []};
        return result;
    }, {...allCategory}) : {...allCategory}

    props?.value?.forEach(i => {
        tabs[allId]?.items.push(i)
        tabs[i.category]?.items.push(i)
    })

    for (const tab in tabs) {
        if (tabs[tab].items.length === 0) delete tabs[tab]
    }

    const [activeTab, setActiveTab] = useState(tabs?.[allId]);
    const [underlineStyle, setUnderlineStyle] = useState({});
    const activeTabRef = useRef(null);
    const tabContainerRef = useRef(null);


    useEffect(() => {
        if (activeTabRef.current) {
            const tab = activeTabRef.current;
            const width = tab.offsetWidth - 8;
            const left = tab.offsetLeft + 4;
            setUnderlineStyle({width, transform: `translateX(${left}px)`, opacity: 1});
        }
    }, [activeTab]);

    const categoryCounts = Object.values(tabs).filter(tab => tab.id !== allId && tab.items.length > 0).length;

    const handleTabClick = (tab, i) => {
        setActiveTab(tab);
        if (tabContainerRef.current) {
            const clickedTabElement = tabContainerRef.current.children[i];
            clickedTabElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
        }
    };

    const caption = (item) => {
        const category = tabs[item.category];
        const categoryName = category?.name ? `${category.name} / ` : '';
        const itemTitle = item?.title;
        const itemText = item.text ? `<div class="rich-text leading-snug text-left">${item.text}</div>` : '';

        return (
            `
        <div class="p-4 my-4 section-white border-base rounded-theme max-w-md inline-block w-96">              
            <h6 class="!mb-2">
                ${categoryName}${itemTitle}
            </h6>
            ${itemText}
        </div>
        `
        );
    }


    return props?.value?.length > 0 ?
        <>
            <h3 className={"!text-xl !my-1"}>{props.label}</h3>
            <div className="block">
                {categoryCounts > 1 && (
                    <nav className="flex overflow-x-scroll relative h-[52px] gap-2 -mb-1" aria-label="Tabs"
                         ref={tabContainerRef}>
                        {Object.keys(tabs).map((key, i) => {
                            const tab = tabs[key];

                            const isActive = tab.id === activeTab.id;
                            return (
                                <div
                                    key={tab.id}
                                    ref={isActive ? activeTabRef : null}
                                    onClick={() => handleTabClick(tab, i)}
                                    className={classNames('px-2.5 py-2 text-base font-semibold text-center cursor-pointer shrink-0 hover:opacity-100 ', isActive ? "opacity-100" : "opacity-70")}
                                >
                                    {tab.name}
                                    <span
                                        className={'ml-1.5 rounded-full px-2 py-0.5 text-xs inline-block bg-gray-100 text-gray-900'}
                                    >
                                            {tab.items.length}
                                        </span>
                                </div>
                            );
                        })}
                        <div
                            className="underline absolute bottom-0 rounded-theme left-0 h-1 transition-[transform,width,opacity] duration-200 bottom-3"
                            style={underlineStyle}></div>
                    </nav>
                )}
                <div>
                    <div className={"w-full pt-2 pb-4"}>
                        <PhotoswipeGallery options={galleryOptions}>
                            <div
                                className={"mb-4 grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-4 text-sm"}>
                                {activeTab?.items?.map((item, i) => {
                                    item.imageState = item.imageState || item.sourceState
                                    item.image = item.image || item.source

                                    return (
                                        <Item
                                            original={useCdn(item.imageState?.__cx__?.key || item.image)}
                                            thumbnail={useCdn(item.imageState?.__cx__?.key || item.image)}
                                            width={global.innerWidth}
                                            height={global.innerHeight}
                                            title={caption(item)}
                                            key={i}>
                                            {({ref, open}) => (
                                                <div
                                                    className={"flex flex-col hover:cursor-pointer hover:opacity-75 relative rounded-theme section-grey"}
                                                    ref={ref}
                                                    onClick={context.builder ? null : open}>
                                                    <Image
                                                        id={item.imageState?.__cx__?.id ? `image_${item.imageState?.__cx__?.id}` : null}
                                                        alt={item.altText}
                                                        src={item.imageState?.__cx__?.key || item.image}
                                                        className={classNames(
                                                            " w-full h-full object-center !rounded-b-none object-cover aspect-square"
                                                        )}/>
                                                    <div className={classNames("p-2")}>
                                                        <div
                                                            className={"font-semibold leading-snug"}>{item.title}</div>
                                                        {item.text ? (
                                                            <div dangerouslySetInnerHTML={{__html: item.text}}
                                                                 className="rich-text leading-snug line-clamp-2"/>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )}
                                        </Item>
                                    )
                                })}
                            </div>
                        </PhotoswipeGallery>
                    </div>
                </div>
            </div>
        </> : null;
}

export default function Gallery(props) {
    const settings = getSettings(props);

    return (
        <List {...props} {...settings}/>
    );
};