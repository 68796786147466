import {Popover, Transition} from '@headlessui/react'
import {Fragment, useContext, useEffect, useRef} from "react";
import {backgroundColour} from "../../utils/block-utils";
import classNames from "classnames";
import useCdnUrl from "../../hooks/cdn-hook";
import {Disclosure} from '@headlessui/react'
import AnimateHeight from 'react-animate-height';

import {StoreActions, handleBackgroundColour, hasStoreActions} from "./index";
import {Header} from "./index";
import Action from "../../components/action";
import {WebsiteContext} from "../../index";
import {Image} from "../../components/image";
import ContactBar from "./contact-bar";

/***
 * Tall Navigation desktop design. Logo and primary button along a top bar and menu items along the bottom.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DesktopNav(props) {
    return (
        <nav
            className={classNames(
                "hidden sm:block",
                handleBackgroundColour(backgroundColour(props.settings?.backgroundColour), 1))
            }>
            <hr className={"h-px w-full opacity-50 brightness-[0.8] contrast-[6]"}
                style={{background: "var(--primary-button-text-colour)"}}/>
            <ul className={"mx-auto max-w-7xl flex flex-wrap justify-center items-center sm:px-8 px-4 p-2.5 space-x-8 text-base gap-y-2.5 w-full"}>
                {props.items?.map(({children = [], ...item}) => (
                    <li key={item.uniqueUri}>
                        {!children.length ? (
                            <Action {...item} button={false} showingActionButton={true}
                                    className={"inline-flex justify-center items-center cursor-pointer"}
                                    style={{fontWeight: '501'}}/>
                        ) : null}
                        {children.length ? (
                            <Popover className="relative">
                                {({open}) => (
                                    <>
                                        <Popover.Button
                                            as={"div"}
                                            role="button"
                                            className={"inline-flex justify-center items-center cursor-pointer"}
                                            style={{fontWeight: '501'}}>
                                            {item.title}
                                            <span className={classNames(
                                                "border-current ml-1 h-6 w-6",
                                                open ? "chevron--up" : "chevron--down"
                                            )}>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    version="1.1"
                                                    viewBox="0 0 100 100"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    preserveAspectRatio="xMidYMid"
                                                    stroke="currentColor">
                                                    <g className="chevron__container">
                                                        <line className="chevron__line1" x1="20" y1="50" x2="50"
                                                              y2="50"/>
                                                        <line className="chevron__line2" x1="80" y1="50" x2="50"
                                                              y2="50"/>
                                                    </g>    
                                                </svg>
                                            </span>
                                        </Popover.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-in duration-100 origin-top"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="transition ease-out duration-100 origin-top"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Popover.Panel
                                                as={"div"}
                                                style={{borderRadius: 'var(--container-roundness)'}}
                                                className={classNames(
                                                    "popover-panel absolute space-y-1 flex flex-col w-44 z-20 px-2 py-2 shadow-[0_2px_6px_-4px_rgba(0,0,0,0.3),0px_10px_20px_-15px_rgba(0,0,0,0.2)] left-1/2 -translate-x-1/2",
                                                    handleBackgroundColour(backgroundColour(props.settings?.backgroundColour), 1)
                                                )}
                                            >
                                                {item.action?.type === 'page' ? (
                                                    <Action {...item} button={false} showingActionButton={true}
                                                            className={"font-light py-1 px-2 hover:bg-black/5 hover:brightness-125"}
                                                            style={{borderRadius: 'calc(var(--container-roundness) / 2)'}}/>
                                                ) : null}
                                                {children.map(({children, ...item}) => (
                                                    <Action
                                                        {...item}
                                                        button={false}
                                                        showingActionButton={true}
                                                        key={item.uniqueUri}
                                                        style={{borderRadius: 'calc(var(--container-roundness) / 2)'}}
                                                        className="font-light py-1 px-2 hover:bg-black/5 hover:brightness-125"
                                                    />
                                                ))}
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>
                        ) : null}
                    </li>
                ))}
            </ul>
        </nav>
    )
}



function MobileNav(props) {
    return (
        <Popover>
            <Popover.Button
                className={"sm:hidden block w-full items-center justify-center font-bold outline-none"}>MENU</Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-in duration-100 origin-top"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-out duration-200 origin-top"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Popover.Panel as={"nav"}
                               className={classNames("sm:hidden block absolute w-full inset-x-0 z-50 mt-[14px] overflow-y-scroll")}>
                    <ul className={classNames("overflow-y-scroll flex flex-col items-start border-t border-b border-gray-200 px-4 pt-2 pb-4 text-md w-full rounded-theme !rounded-t-none shadow-[0_10px_6px_-12px_rgba(0,0,0,0.2),0px_8px_26px_-15px_rgba(0,0,0,0.2)]",
                        handleBackgroundColour(backgroundColour(props.settings?.backgroundColour), 0))}>
                        {hasStoreActions() &&
                            <li className={"w-full"}>    
                                <Action {...props.settings} className={"text-base truncate w-full my-2 text-center !block"} title={null}/>
                            </li>
                        }
                        {props.settings?.details &&
                            <li dangerouslySetInnerHTML={{__html: props.settings?.details}}
                                className={"w-full rich-text text-left leading-[normal] my-2"}/>
                        }
                        {props.items?.map(({children = [], ...item}) => (
                            <Disclosure as="li" key={item.uniqueUri} className={"w-full"}>
                                {({open}) => (
                                    <>
                                        {!children.length ? (
                                            <Action {...item} button={false} showingActionButton={true}
                                                    className={"flex justify-between items-center w-full py-1.5"}
                                                    style={{fontWeight: '501'}}/>
                                        ) : null}
                                        {children.length ? (
                                            <>
                                                <Disclosure.Button as={"div"}
                                                                   className={"flex justify-between items-center w-full py-1.5"} style={{fontWeight: '501'}}>
                                                    <span>{item.title}</span>
                                                    <span className={classNames(
                                                        "border-current h-6 w-6",
                                                        open ? "chevron--up" : "chevron--down"
                                                    )}>
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            version="1.1"
                                                            viewBox="0 0 100 100"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            preserveAspectRatio="xMidYMid"
                                                            stroke="currentColor">
                                                            <g className="chevron__container">
                                                                <line className="chevron__line1" x1="10" y1="50" x2="50"
                                                                      y2="50"/>
                                                                <line className="chevron__line2" x1="90" y1="50" x2="50"
                                                                      y2="50"/>
                                                            </g>    
                                                        </svg>
                                                    </span>
                                                </Disclosure.Button>
                                                {item.action?.type === 'page' ? (
                                                    <Action {...item} button={false} showingActionButton={true}
                                                            className={"font-light"}/>
                                                ) : null}
                                                <Disclosure.Panel as="ul" static
                                                                  className={classNames("text-md flex flex-col")}>
                                                    <AnimateHeight height={open ? 'auto' : 0} animateOpacity={true}
                                                                   className={"-mt-2"}>
                                                        {children.map(({children = [], ...item}) => (
                                                            <li className={"w-full py-1.5"} key={item.uniqueUri}>
                                                                <Action {...item} button={false}
                                                                        showingActionButton={true}
                                                                        className={"block w-full"}/>
                                                            </li>
                                                        ))}
                                                    </AnimateHeight>
                                                </Disclosure.Panel>
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </ul>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}


export default function Design1(props) {
    const context = useContext(WebsiteContext);

    return (
        <>
            {props?.settings?.showingContactBar && <ContactBar {...props} />}
            <Header {...props} className={classNames(
                "top-0 z-20 sm:min-h-[7.3125rem]",
                context.builder ? "" : "sticky",
                handleBackgroundColour(backgroundColour(props.settings?.backgroundColour), 0)
            )}>
                <div className={classNames(
                    "mx-auto max-w-7xl px-4 sm:px-8 flex justify-between items-center flex-row h-[60px] py-2 sm:py-4 sm:h-auto",
                handleBackgroundColour(backgroundColour(props.settings?.backgroundColour), 0)
            )}>
                <div className={"pr-2 h-full shrink-0"}>
                    <a href={"/"}
                       title={"Go Home"} 
                       className={classNames("h-full w-full flex items-center max-w-[144px] sm:max-h-[56px]",
                           props.logoAspectRatio >= 1.25 && props.logoAspectRatio < 6 ? "sm:max-w-[192px]" : null,
                           props.logoAspectRatio >= 6 ? "sm:max-w-[256px]" : null
                       )}
                    >
                        {props.settings?.logo ?
                            <Image
                                pictureClassName={"h-full flex items-center"}
                                alt={"Go Home"}
                                width={320}
                                imageWidth={props.settings?.image?.width}
                                imageHeight={props.settings?.image?.height}
                                src={props.settings?.logo}
                                className={"h-full object-contain object-left sm:max-h-[56px]"}
                            />
                            :
                            <h1 className={"fit-text"}>{props.title}</h1>
                        }
                    </a>
                </div>
                
                    <div className={classNames("flex items-center gap-4", hasStoreActions() && "flex-row-reverse sm:flex-row")}>
                        {props.items.length > 0 &&
                            <MobileNav {...props} />
                        }
                        {props.settings?.details &&
                            <div dangerouslySetInnerHTML={{__html: props.settings?.details}}
                                 className={"w-full rich-text text-right leading-[normal] hidden sm:block"}/>
                        }

                        {/* Divider if there is actions and details */}
                        {props.settings?.details !== undefined && props.settings?.details !== null && props.settings?.details !== "" && (props.settings?.showingActionButton || hasStoreActions()) &&
                            <div className="self-stretch hidden sm:block">
                                <div className="w-px h-full bg-gray-500 rounded-theme underline"/>
                            </div>
                        }
                        <StoreActions {...props} context={context} />
                        {/*Hide the primary action on mobile if we have any store actions turned on*/}
                        {hasStoreActions() && <Action {...props.settings} className={"!hidden sm:!block text-base truncate shrink-0"} title={null}/>}
                        
                        {/*Render the primary action as normal if there's no store actions*/}
                        {!hasStoreActions() && <Action {...props.settings} className={"text-base truncate shrink-0"} title={null}/>}
                    </div>
                    
                </div>
                <DesktopNav {...props} />
            </Header>
        </>
    )
}