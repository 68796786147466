import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import _get from 'lodash/get';
import _set from 'lodash/set';
import _mergeWith from 'lodash/mergeWith';
import {arrayMove} from "@dnd-kit/sortable";

import PlusIcon from '@heroicons/react/solid/PlusCircleIcon'
import MenuIcon from '@heroicons/react/solid/MenuIcon'
import MoreIcon from "@autocx/icons/src/more-icon";

import FormContext from "../form-context";
import {getValuesIncludingHidden, prefixFieldNames} from "../utils"

import {Menu, Transition} from "@headlessui/react";
import _cloneDeep from "lodash/cloneDeep";
import FormLayout from "../layouts/form-layout";
import Form from "../index";
import _isArray from "lodash/isArray";
import _isFunction from "lodash/isFunction";
import {nanoid} from "nanoid/non-secure";
import _isEqual from "lodash/isEqual";
import EyeSlashIcon from "@autocx/icons/src/eye-slash-icon";

const POSITIONS_FIELD_NAME_SUFFIX = 'Positions'

export const getHideOnIcon = (value) => {
    switch (value) {
        case 'mobile':
            return (<svg className="w-6 h-6" viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(20, 13)" fillRule="nonzero">
                        <rect fill="#000000" opacity="0" x="4.97512701e-07" y="0" width="87.9999249"
                              height="102"></rect>
                        <path
                            d="M67.4240988,61.0458687 L75.2463895,68.8031538 L75.2463895,12.5271658 C75.2463895,5.01086631 69.9505671,0 62.031162,0 L26.0295599,0 C20.2478668,0 15.923786,2.50543316 14.320502,7.13085277 L13.9804024,7.99812265 L20.6365236,14.5989591 L20.6365236,13.6835454 C20.6365236,9.82901915 22.9201063,7.75721599 27.001202,7.75721599 L61.0108632,7.75721599 C65.0920583,7.75721599 67.4240988,9.92538575 67.4240988,13.7798528 L67.4240988,61.0458687 Z M38.175832,18.3571187 L49.9334472,18.3571187 C51.8282736,18.3571187 53.3344431,16.8634658 53.3344431,14.9362323 C53.3344431,13.0571525 51.8282736,11.5635984 49.9334472,11.5635984 L38.175832,11.5635984 C36.2324483,11.5635984 34.6777215,13.0571525 34.6777215,14.9362323 C34.6777215,16.8634658 36.2324483,18.3571187 38.175832,18.3571187 Z M26.0295599,102 L62.031162,102 C67.6184272,102 71.6996224,99.5429771 73.5458915,95.158326 C73.643006,94.9174588 73.7887772,94.5801855 73.8859911,94.3393184 L67.4240988,87.7384129 C67.4240988,88.0756861 67.4240988,88.3648057 67.4240988,88.6539253 C67.2297704,92.2675251 64.8977299,94.2429123 61.0108632,94.2429123 L27.001202,94.2429123 C22.9201063,94.2429123 20.6365236,92.0747129 20.6365236,88.2202459 L20.6365236,41.4842167 L12.8143324,33.7270303 L12.8143324,89.4729329 C12.8143324,96.9892521 18.1101548,102 26.0295599,102 Z M33.70598,90.7257185 L54.3547419,90.7257185 C55.6664836,90.7257185 56.6382251,89.8102062 56.6382251,88.4611131 C56.6382251,87.11202 55.6664836,86.24476 54.3547419,86.24476 L33.70598,86.24476 C32.3942383,86.24476 31.471054,87.11202 31.471054,88.4611131 C31.471054,89.8102062 32.3942383,90.7257185 33.70598,90.7257185 Z M81.6109684,93.7610792 C83.0685807,95.2547321 85.4978351,95.2065784 86.9067907,93.7610792 C88.3644031,92.3638325 88.3644031,90.0029196 86.9067907,88.5093655 L6.40110675,8.72084261 C4.94354416,7.27540268 2.56286688,7.22721938 1.05672716,8.72084261 C-0.352242386,10.1180992 -0.352242386,12.5753195 1.05672716,13.9726649 L81.6109684,93.7610792 Z"
                            fill="#4B5563"></path>
                    </g>
                </g>
            </svg>);
        case 'desktop':
            return (<svg className="w-6 h-6" viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.0009, 13.0719)" fill="#4B5563">
                        <polygon fillRule="nonzero"
                                 points="38.4780279 88.9983156 64.4175646 88.9983156 64.4175646 78.8271846 38.4780279 78.8271846"></polygon>
                        <path
                            d="M38.2220456,94.0624229 L64.6308832,94.0624229 C65.5694848,94.0624229 66.3800954,93.7262463 67.0627147,93.0538931 C67.7453341,92.3815398 68.0866438,91.55898 68.0866438,90.5862136 C68.0866438,89.642058 67.7453341,88.8194982 67.0627147,88.1185342 C66.3800954,87.4175702 65.5694848,87.0670882 64.6308832,87.0670882 L38.2220456,87.0670882 C37.2834439,87.0670882 36.4728334,87.4175702 35.790214,88.1185342 C35.1075946,88.8194982 34.7662849,89.642058 34.7662849,90.5862136 C34.7662849,91.55898 35.1075946,92.3815398 35.790214,93.0538931 C36.4728334,93.7262463 37.2834439,94.0624229 38.2220456,94.0624229 Z"
                            fillRule="nonzero"></path>
                        <path
                            d="M3.9057305,15.9209158 L10.0638285,22.0676691 L10.0639958,59.4290784 C10.0639958,60.5403812 10.5617785,61.1252774 11.5573439,61.183767 L11.7278806,61.1886411 L49.2488285,61.1876691 L67.6058285,79.5136691 L12.5811548,79.5138433 C9.66461606,79.5138433 7.35540563,78.7255568 5.6535235,77.1489838 L5.43498308,76.9388734 C3.71421338,75.2222268 2.85382852,72.833227 2.85382852,69.771874 L2.85382852,20.933279 C2.85382852,18.997231 3.20446251,17.3264432 3.9057305,15.9209158 Z M90.2717739,11.1913097 C93.3151187,11.1913097 95.697176,12.0567857 97.4179457,13.7877377 C99.1387154,15.5186896 99.9991002,17.9005367 99.9991002,20.933279 L99.9991002,69.771874 C99.9991002,72.833227 99.1387154,75.2222268 97.4179457,76.9388734 C95.9303983,78.4228565 93.9486694,79.2654129 91.472759,79.4665426 L73.1638285,61.1876691 L91.1250482,61.1886411 C92.2028683,61.1886411 92.770142,60.6622345 92.8268694,59.6094214 L92.8315967,59.4290784 L92.8315967,21.2766083 C92.8315967,20.3610635 92.5756144,19.6529468 92.0636498,19.1522582 C91.5516853,18.6515696 90.8548447,18.4012253 89.973128,18.4012253 L30.3048285,18.4006691 L23.0828285,11.1906691 Z"
                            fillRule="nonzero"></path>
                        <path
                            d="M49.8729623,-16.6692069 C52.0551376,-16.6644465 53.8221309,-14.8950708 53.8239513,-12.712891 L53.9284725,112.587145 C53.9302855,114.760487 52.1699132,116.523799 49.9965711,116.525612 C49.9924039,116.525615 49.9882368,116.525612 49.9840696,116.525603 C47.8017916,116.52049 46.034901,114.750932 46.0330806,112.568649 L45.9285594,-12.7301149 C45.9267462,-14.9038065 47.6874015,-16.6674016 49.8610931,-16.6692149 C49.8650495,-16.6692182 49.8690059,-16.6692155 49.8729623,-16.6692069 Z"
                            transform="translate(49.9285, 49.9285) rotate(-45) translate(-49.9285, -49.9285)"></path>
                    </g>
                </g>
            </svg>);
        case 'both':
            return <EyeSlashIcon className="w-6 h-6"/>;
        default:
            return;
    }
}

/**
 * Handles adding a new item to the list
 *
 * @param context The form context
 * @param props The list props
 * @param listRef The react ref to the list
 * @param existing A reference to an existing item
 * @param items The current list of items in the list state
 */
const addItem = (context, props, listRef, existing, items) => {
    const values = _get(context.values, props.name, []) || [];
    const item = getValuesIncludingHidden(props.fieldsets, props.hiddenFields);

    if (!existing) {
        item.id = nanoid()

        // Add custom metadata to the newly added item so that we can use it later on in this control
        Object.defineProperty(item, '__cx__', {
            enumerable: false, // Make sure we don't serialised this field
            value: {added: true},
            writable: false,
        });
    } else {
        // Add custom metadata to the newly added item so that we can use it later on in this control
        Object.defineProperty(item, '__cx__', {
            enumerable: false, // Make sure we don't serialised this field
            value: {edit: true},
            writable: false,
        });

        // Merge existing item with new item
        _mergeWith(item, existing, (objValue, srcValue) => {
            if (_isArray(objValue)) {
                return srcValue;
            }
        });
    }

    if (_isFunction(props.beforeItemAdd)) {
        props.beforeItemAdd(item);
    }


    // Handle if we are adding objects of primitive types to an array
    if (props.fieldsets.length === 1 && props.fieldsets[0].fields.length === 1 && !props.fieldsets[0].fields[0].name) {
        props.onFieldChange({target: listRef.current}, props.name, [...values, '']);
    } else {
        // Handle when the list is inherited and set the position array  
        if (!!context.original) {
            const positionsFieldName = getPositionFieldName(context, props)
            props.onFieldChange({target: listRef.current}, positionsFieldName, [...items.map(item => item.id), item.id]);
        }

        props.onFieldChange({target: listRef.current}, props.name, [...values, item]);
    }
}

/**
 * Handles duplicating an existing item in the list
 *
 * @param context The form context
 * @param props The list item props
 */
const duplicateItem = (context, props) => {
    const values = _get(context.values, props.name, []) || [];
    const item = _cloneDeep(props.item)

    // Handle if we are adding objects of primitive types to an array
    if (props.fieldsets.length === 1 && props.fieldsets[0].fields.length === 1 && !props.fieldsets[0].fields[0].name) {
        props.onFieldChange({target: props.listRef.current}, props.name, [...values, '']);
    } else {
        item.id = nanoid()


        if (item.imageState?.__cx__) {
            // If there's an image in the item let's update its ID so it maintains its own state
            item.imageState.__cx__.id = nanoid();
        }

        const newValues = [...values, item]

        // Handle when the list is inherited and set the position array  
        if (!!context.original) {
            const positionsFieldName = getPositionFieldName(context, props)
            props.onFieldChange({target: props.listRef.current}, positionsFieldName, [...props.items.map(item => item.id), item.id]);
        }

        props.onFieldChange({target: props.listRef.current}, props.name, newValues);
    }
}

/**
 * Handles editing an item in the list
 *
 * @param context The form context
 * @param props The list item props
 */
const editItem = (context, props) => {
    const fieldsets = prefixFieldNames(props.fieldsets, props.prefix);
    const original = _cloneDeep(props.item);
    const fileUploadSettings = _isFunction(props.fileUploadSettings)
        ? props.fileUploadSettings(props.item)
        : props.fileUploadSettings;

    context.setContext({
        showChildForm: true,
        childForm: (
            <Form
                id={"child_form"}
                layout={"panel-style"}
                className={"h-full"}
                fileUploadSettings={fileUploadSettings || context.fileUploadSettings}
                heading={props.item.__cx__?.added === true
                    ? `Add ${props.defaultItemLabel}`
                    : `Edit ${props.defaultItemLabel}`}
                fieldsets={fieldsets}
                parentContext={context}
                selectedNode={context.selectedNode}
                values={context.values}
                original={context.original}
                hrefUriParams={context.hrefUriParams}
                actions={[
                    {label: "Cancel", back: true},
                    {label: "Done", primary: true, icon: "check"},
                    {label: "Remove", color: "error", icon: "trash", destructive: true}
                ]}
                onSubmit={(e, values, onProgress) => {
                    const updated = [].concat(_get(values, props.name, []));

                    props.onFieldChange({target: props.listRef.current}, props.name, updated);

                    return (
                        _isFunction(props.onItemChange)
                            ? Promise.all([props.onItemChange(e, updated[props.itemIndex], updated, onProgress)])
                            : Promise.resolve()
                    ).finally(() => {
                        // Transition back to the previous form
                        context.setContext({showChildForm: false});
                    });
                }}
                onActionClick={(e, action) => {
                    // When navigating back if the item was added then remove it otherwise revert it
                    if (action.back) {
                        const values = [].concat(_get(context.values, props.name, []));

                        // Remove the items if it was added
                        if (props.item.__cx__?.added === true) {
                            values.splice(props.itemIndex, 1);
                        }
                        // Otherwise revert the changes
                        else {
                            values.splice(props.itemIndex, 1, original);
                        }

                        props.onFieldChange({target: props.listRef.current}, props.name, values);
                    }

                    // Handle when removing the item
                    if (action.destructive === true) {
                        e.stopPropagation();
                        deleteItem(context, props)
                    }

                    // Transition back to the previous form
                    context.setContext({showChildForm: false});
                }}
                onFieldChange={(e, name, value) => {
                    props.onFieldChange(e, name, value);
                }}
            />
        )
    });
}

/**
 * Handles removing and item from the list
 *
 * @param context The form context
 * @param props The list item props
 */
const deleteItem = (context, props) => {
    if (!confirm('Are you sure?')) return
    const values = [].concat(_get(context.values, props.name, []) || []);

    // When deleting an item from an inherited list, then softly delete items that are inherited.
    // Items that have been added in any case are deleted.
    if (!!context.original) {
        const originalValues = [].concat(_get(context.original, props.name, []) || []);
        const originalIds = originalValues.map(item => item.id)

        const item = values[props.itemIndex]
        if (originalIds.includes(item.id)) {
            _set(values, `${props.itemIndex}.isDeleted`, true)
        } else {
            values.splice(props.itemIndex, 1);

            // Remove this item from the position array and re-sync it
            const positionFieldName = getPositionFieldName(context, props)
            const positions = (_get(context.values, positionFieldName, []) || []).filter(position => position !== item.id)
            const originalValues = [].concat(_get(context.original || {}, props.name, []))
            const originalPositions = originalValues.map(({id}) => id)

            // If the array matches the original state, then clear it
            if (_isEqual(positions, originalPositions)) {
                props.onFieldChange({target: props.listRef.current}, positionFieldName, null);
            } else {
                props.onFieldChange({target: props.listRef.current}, positionFieldName, positions);
            }
        }
    }
    // Otherwise, remove the item from the list
    else {
        values.splice(props.itemIndex, 1);
    }

    props.onFieldChange({target: props.listRef.current}, props.name, values);
}

/**
 * Returns the field name of the array which will store positional data for the list of items.
 *
 * @param context The form context
 * @param props The list props
 * @return {string}
 */
function getPositionFieldName(context, props) {
    return `${props.name}${POSITIONS_FIELD_NAME_SUFFIX}`
}

/**
 * Handles setting an additional field using the current name of this field to capture the sort positions of items
 *
 * @param context The form context
 * @param props The list props
 * @param listRef An element reference to the list react element
 * @param values The current state of the list values
 */
const populateItemPositions = (context, props, listRef, values) => {
    if (!props.sortable) return

    if (!context.original) {
        props.onFieldChange({target: listRef.current}, props.name, values);
        return
    }

    const positionsFieldName = getPositionFieldName(context, props)
    const originalValues = [].concat(_get(context.original || {}, props.name, []))
    const originalPositions = originalValues.map(({id}) => id)
    const positions = values.map(({id}) => id)

    // If the array matches the original state, then clear it
    if (_isEqual(positions, originalPositions)) {
        props.onFieldChange({target: listRef.current}, positionsFieldName, null);
    } else {
        props.onFieldChange({target: listRef.current}, positionsFieldName, positions);
    }
}


const DragHandle = SortableHandle(() => (
    <div className="flex items-center cursor-pointer">
        <MenuIcon className="h-5 w-5 text-panels-700" aria-hidden="true"/>
    </div>
));

const DefaultSortableItem = SortableElement(({item, ...props}) => {
    const fieldsets = prefixFieldNames(props.fieldsets, props.prefix);
    const context = useContext(FormContext);
    const label = _get(item, props.labelFieldPath)

    return (
        <li className={"list-none select-none"}>
            <FormLayout
                {...props}
                layout={"panel-list-form"}
                className={classNames("w-full bg-panels-200 rounded py-2 px-3", props.itemIndex > 0 ? "mt-2" : null)}
                actions={[{label: "Remove", color: "error", icon: "trash"}]}
                fieldsets={fieldsets.map(fieldset => ({
                    ...fieldset,
                    collapsable: fieldset.collapsable !== false,
                    expanded: fieldset.collapsable !== false && item.__cx__?.added === true
                }))}
                onActionClick={() => {
                    const values = [].concat(_get(context.values, props.name, []));
                    values.splice(props.itemIndex, 1);
                    props.onFieldChange({target: props.listRef.current}, props.name, values);
                }}
            >
                {props.sortable ? (
                    <DragHandle className={"shrink-0"}/>
                ) : null}
                <span className={classNames(
                    "grow text-sm font-semibold text-panels-700 text-left text-ellipsis overflow-hidden",
                    props.sortable ? "mx-2" : "mr-2"
                )}
                      dangerouslySetInnerHTML={{__html: label ? label : `${props.defaultItemLabel} ${props.itemIndex + 1}`}}/>
            </FormLayout>
        </li>
    );
});

const NavigableSortableItem = SortableElement((props) => {
    const context = useContext(FormContext);
    const label = _get(props.item, props.labelFieldPath);
    useEffect(() => {
        if (props.item.__cx__?.added === true || props.item.__cx__?.edit) {
            editItem(context, props);
        }
    }, []);

    return (
        <li
            className={classNames(
                "list-none bg-white flex flex-row items-center justify-between rounded border border-gray-500 shadow-button py-1 px-1.5 cursor-pointer",
                // Hide the item if it's just been added
                props.item.__cx__?.added === true ? "hidden" : null
            )}
            onClick={() => editItem(context, props)}
        >
            {props.sortable ? (
                <DragHandle className={"shrink-0"}/>
            ) : null}
            <span className={classNames(
                "grow text-sm font-semibold text-panels-700 text-left text-ellipsis overflow-hidden",
                props.sortable ? "mx-2" : "mr-2"
            )}>
                    {label ? label.replace(/<[^>]*>/g, ' ').replace(/&nbsp;/g, ' ').replace(/\s+/g, ' ').trim() : `${props.defaultItemLabel} ${props.itemIndex + 1}`}
                </span>
            {
                props.item?.hideItem && (
                    <span className={"w-6 h-6 text-gray-500"}>
                                {getHideOnIcon(props.item.hideOn)}
                            </span>
                )
            }
            <Menu as="div" className="-ml-px relative flex items-center">
                <Menu.Button
                    className="bg-panels-100 cursor-pointer text-gray-500 relative inline-flex items-center rounded-full font-medium focus:outline-none hover:bg-panels-200 p-1"
                    onClick={(e) => e.stopPropagation()}
                >
                    <MoreIcon className="h-4 w-4" aria-hidden="true"/>
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="origin-top-right absolute z-10 right-0 mt-2 -mr-1 w-48 menu-container">
                        <div className="flex flex-col">
                            <Menu.Item
                                as={"button"}
                                type={"button"}
                                className={"grow block menu-item-primary"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItem(context, props)
                                }}
                            >Edit</Menu.Item>
                            <Menu.Item
                                as={"button"}
                                type={"button"}
                                className={"grow block menu-item"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    duplicateItem(context, props)
                                }}
                            >Duplicate</Menu.Item>
                            <Menu.Item
                                as={"button"}
                                type={"button"}
                                className={"grow block menu-item-danger"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deleteItem(context, props)
                                }}
                            >Remove</Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </li>
    )
});

const SortableList = SortableContainer((props) => {
    const context = useContext(FormContext);
    const Item = context.layout === 'panel-style' ? NavigableSortableItem : DefaultSortableItem;

    // Computes a lookup of index positions which correlate to the actual value in form values. 
    // When inheriting changes and the user has changed sort positions, then when modifying the list items, we need to
    // reference the original index not the user-defined one
    const indexLookup = useMemo(() => {
        const items = _get(context.values, props.name, []) || []

        return items.reduce((result, item, index) => {
            if (item.id) {
                result[item.id] = index
            }
            return result
        }, {})
    }, [context.values, props.name])

    return (
        <ul ref={props.ref} className={"sortable-list space-y-2"}>
            {props.items.map((item, index) => {
                const itemIndex = indexLookup[item.id] ?? index
                const prefix = `${props.name}[${itemIndex}]`;
                return (
                    <Item
                        {...props}
                        key={prefix}
                        index={index}
                        itemIndex={itemIndex}
                        item={item}
                        prefix={prefix}
                        items={props.items}
                    />
                )
            })}
        </ul>
    )
});

List.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    fieldsets: PropTypes.array,
    hiddenFields: PropTypes.object,
    name: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    onFieldChange: PropTypes.func.isRequired,
    onItemChange: PropTypes.func,
    placeholder: PropTypes.string,
    labelFieldPath: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    errorMessage: PropTypes.string,
    defaultItemLabel: PropTypes.string,
    onSelectExisting: PropTypes.func,
    beforeItemAdd: PropTypes.func,
    fileUploadSettings: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

List.defaultProps = {
    onFieldChange: () => {},
    onItemChange: () => {},
    defaultItemLabel: "Item",
    beforeItemAdd: () => {},
}

export default function List(props) {
    const context = useContext(FormContext);
    const listRef = useRef();
    const containerRef = useRef();
    const [items, setItems] = useState([]);
    const showEdit = props.editing && !props.system;

    useEffect(() => {
        let items = [].concat(_get(context.values, props.name, []) || []).map(i => i.id ? i : {...i, id: nanoid()});
        const positionsFieldName = `${props.name}${POSITIONS_FIELD_NAME_SUFFIX}`
        const positions = _get(context.values, positionsFieldName)

        items = items.filter(item => !item.isDeleted)

        // Handle when this control is sortable and there are sort positions defined
        if (props.sortable && positions?.length) {
            items.sort((a, b) => positions.indexOf(a.id) - positions.indexOf(b.id))
        }

        setItems(items)
    }, [context.values, props.name]);

    return (
        <div id={props.id} ref={containerRef} className={classNames("relative", props.className)}>
            <div className={classNames("flex mb-2", props.label ? "justify-between" : "justify-end")}>
                {props.label ? (
                    <label htmlFor={props.id} className={"block text-sm font-semibold text-gray-700"}>
                        {props.label}
                    </label>
                ) : null}
                {showEdit ? (
                    <span
                        className={"text-xs font-medium text-primary-500 leading-5 cursor-pointer hover:underline"}
                        onClick={props.onEditFieldSettings}
                    >Edit</span>
                ) : null}
            </div>
            {props.description ? (
                <p className="mt-1 mb-0 text-xs text-gray-500">
                    {props.description}
                </p>
            ) : null}
            {items.length ? (
                <SortableList
                    ref={listRef}
                    listRef={listRef}
                    {...props}
                    items={items}
                    useDragHandle
                    getContainer={() => {
                        let parent = containerRef.current.parentElement;
                        while (!(parent.tagName === 'BODY' || parent.scrollHeight > parent.offsetHeight)) {
                            parent = parent.parentElement;
                        }

                        return parent || document.body;
                    }}
                    helperClass={"sort-helper"}
                    // Adds z-index to sort helper to handle cases when rendering this control in a modal
                    onSortStart={() => {
                        const helper = document.getElementsByClassName("sort-helper")[0];
                        helper.style.zIndex = 50000;
                    }}
                    onSortEnd={({oldIndex, newIndex}) => {
                        const newValues = arrayMove(items, oldIndex, newIndex)
                        populateItemPositions(context, props, listRef, newValues)
                    }}
                />
            ) : null}
            {props.onSelectExisting ? (
                <Menu as="div" className="-ml-px relative block">
                    <Menu.Button
                        className="items-center mt-3 flex flex-row shadow-button border border-primary-500 h-8 px-2 shrink-0 font-semibold leading-4 rounded-md text-blue-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2">
                        <PlusIcon className="h-5 w-5 mr-1" aria-hidden="true"/>
                        Add {props.defaultItemLabel}
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="origin-top-right absolute z-10 left-0 mt-2 -mr-1 w-48 rounded-md shadow-lg bg-white focus:outline-none">
                            <div className="flex flex-col">
                                <Menu.Item
                                    as={"button"}
                                    type={"button"}
                                    className={"grow block menu-item"}
                                    onClick={() => {
                                        props.onSelectExisting()
                                            .then((existing) => {
                                                addItem(context, props, listRef, existing, items);
                                            })
                                    }}
                                >Select existing {props.defaultItemLabel.toLowerCase()}</Menu.Item>
                                <Menu.Item
                                    as={"button"}
                                    type={"button"}
                                    className={"grow block menu-item"}
                                    onClick={() => addItem(context, props, listRef, null, items)}
                                >Create new {props.defaultItemLabel.toLowerCase()}</Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            ) : (
                <button
                    type={"button"}
                    disabled={props.disabled}
                    className="items-center mt-3 flex flex-row shadow-button border border-blue-500 h-8 px-2 shrink-0 font-semibold leading-4 rounded-md text-primary-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2"
                    onClick={() => addItem(context, props, listRef, null, items)}
                >
                    <PlusIcon className="h-5 w-5 mr-1" aria-hidden="true"/>
                    Add {props.defaultItemLabel}
                </button>
            )}
        </div>
    );
};