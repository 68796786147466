import { Popover, Transition } from '@headlessui/react'
import { Fragment, useContext } from "react";
import {backgroundColour} from "../../utils/block-utils";
import classNames from "classnames";
import { Disclosure } from '@headlessui/react'
import AnimateHeight from 'react-animate-height';

import {AccountButton, handleBackgroundColour, hasStoreActions, StoreActions} from "./index";
import {Header} from "./index";
import Action from "../../components/action";
import { WebsiteContext } from "../../index";
import {Image} from "../../components/image";
import ContactBar from "./contact-bar";

/* aka. compact navigation */

function DesktopNav({context, ...props}) {
    return (
        <nav
            className={classNames(
                "hidden sm:flex items-center w-full",
                handleBackgroundColour(backgroundColour(props.settings?.backgroundColour), 0))
            }>
            <ul className={"flex flex-wrap justify-end items-center space-x-8 text-base h-full w-full pl-6"}>
                {props.items?.map(({children = [], ...item}) => (
                    <li key={item.uniqueUri}>
                        {!children.length ? (
                            <Action {...item} button={false} showingActionButton={true} className={"inline-flex justify-center items-center cursor-pointer"} style={{fontWeight: '501'}} />
                        ) : null}
                        {children.length ? (
                            <Popover className="relative">
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            as={"div"}
                                            role="button"
                                            className={"inline-flex justify-center items-center cursor-pointer"}
                                            style={{fontWeight: '501'}}
                                        >
                                            {item.title}
                                            <span className={classNames(
                                                "border-current ml-1 h-6 w-6",
                                                open ? "chevron--up" : "chevron--down"
                                            )}>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    version="1.1"
                                                    viewBox="0 0 100 100"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    preserveAspectRatio="xMidYMid"
                                                    stroke="currentColor">
                                                    <g className="chevron__container">
                                                        <line className="chevron__line1" x1="20" y1="50" x2="50" y2="50"/>
                                                        <line className="chevron__line2" x1="80" y1="50" x2="50" y2="50"/>
                                                    </g>    
                                                </svg>
                                            </span>
                                        </Popover.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-in duration-100 origin-top-right"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="transition ease-out duration-100 origin-top-right"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Popover.Panel
                                                as={"div"}
                                                style={{borderRadius: 'var(--container-roundness)'}}
                                                className={classNames(
                                                    "popover-panel absolute space-y-1 flex flex-col w-44 z-20 px-2 py-2 shadow-[0_2px_6px_-4px_rgba(0,0,0,0.3),0px_10px_20px_-15px_rgba(0,0,0,0.2)] section-white right-0"
                                                )}
                                            >
                                                {item.action?.type === 'page' ? (
                                                    <Action
                                                        {...item}
                                                        button={false}
                                                        showingActionButton={true}
                                                        className="font-light py-1 px-2 hover:bg-black/5 section-white hover:brightness-125"
                                                        style={{borderRadius: 'calc(var(--container-roundness) / 2)'}}
                                                    />
                                                ) : null}
                                                {children.map(({children = [], ...item}) => (
                                                    <Action 
                                                        {...item} 
                                                        button={false}
                                                        showingActionButton={true}
                                                        key={item.uniqueUri}
                                                        style={{borderRadius: 'calc(var(--container-roundness) / 2)'}}
                                                        className="font-light py-1 px-2 hover:bg-black/5 section-white hover:brightness-125"
                                                    />
                                                ))}
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>
                        ) : null}
                    </li>
                ))}
            </ul>
            {/*{context.website?.store?.canSearch &&*/}
            {/*    <div className={"flex items-center justify-center ml-4"}>*/}
            {/*        <svg width="16" height="16">*/}
            {/*            <path*/}
            {/*                d="M6 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM0 6a6 6 0 1 1 10.89 3.477l4.817 4.816a1 1 0 0 1-1.414 1.414l-4.816-4.816A6 6 0 0 1 0 6z"*/}
            {/*                fill="currentColor" fillRule="evenodd"/>*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{context.website?.store?.accountManagementEnabled &&*/}
            {/*    <AccountButton className={"ml-6"}/>*/}
            {/*}*/}
            {/*{context.website?.store?.hasShoppingCart &&*/}
            {/*    <div className={"flex justify-end ml-4"}>*/}
            {/*        <button className={"cart badge"} data-badge={"0"}>*/}
            {/*            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"*/}
            {/*                 viewBox="0 0 24 24"*/}
            {/*                 stroke="currentColor">*/}
            {/*                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
            {/*                      d="M3 3h2l.4 2M7 13h10l4-8h3.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>*/}
            {/*            </svg>*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*}*/}
        </nav>
    )
}

function MobileNav(props) {
    return (
        <Popover>
            <Popover.Button className={"sm:hidden block w-full items-center justify-center font-bold outline-none"}>MENU</Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-in duration-100 origin-top"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-out duration-200 origin-top"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Popover.Panel as={"nav"} className={classNames("sm:hidden block absolute w-full inset-x-0 z-50 mt-[14px] overflow-y-scroll")}>
                    <ul className={classNames("overflow-y-scroll flex flex-col items-start border-t border-b border-gray-200 px-4 pt-2 pb-4 text-md w-full rounded-theme !rounded-t-none shadow-[0_10px_6px_-12px_rgba(0,0,0,0.2),0px_8px_26px_-15px_rgba(0,0,0,0.2)]", 
                        handleBackgroundColour(backgroundColour(props.settings?.backgroundColour), 0))}>
                        {hasStoreActions() &&
                            <li className={"w-full"}>
                                <Action {...props.settings} className={"text-base truncate w-full my-2 text-center !block"} title={null}/>
                            </li>
                        }
                        {props.items?.map(({children = [], ...item}) => (
                            <Disclosure as="li" key={item.uniqueUri} className={"w-full"}>
                                {({ open }) => (
                                    <>
                                        {!children.length ? (
                                            <Action 
                                                {...item} 
                                                button={false}
                                                showingActionButton={true}
                                                style={{fontWeight: '501'}}
                                                className={"flex justify-between items-center w-full py-1.5"}
                                            />
                                        ) : null}
                                        {children.length ? (
                                            <>
                                                <Disclosure.Button
                                                    as={"div"}
                                                    style={{fontWeight: '501'}}
                                                    className={"flex justify-between items-center w-full py-1.5"}
                                                >
                                                    <span>{item.title}</span>
                                                    <span className={classNames(
                                                        "border-current h-6 w-6",
                                                        open ? "chevron--up" : "chevron--down"
                                                    )}>
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            version="1.1"
                                                            viewBox="0 0 100 100"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            preserveAspectRatio="xMidYMid"
                                                            stroke="currentColor">
                                                            <g className="chevron__container">
                                                                <line className="chevron__line1" x1="10" y1="50" x2="50" y2="50"/>
                                                                <line className="chevron__line2" x1="90" y1="50" x2="50" y2="50"/>
                                                            </g>    
                                                        </svg>
                                                    </span>
                                                </Disclosure.Button>
                                                {item.action?.type === 'page' ? (
                                                    <Action {...item} button={false} showingActionButton={true} className={"font-light"} />
                                                ): null}
                                                <Disclosure.Panel as="ul" static className={classNames("text-md flex flex-col")}>
                                                    <AnimateHeight height={open ? 'auto' : 0} animateOpacity={true} className={"-mt-2"}>
                                                        {children.map(({children = [], ...item}) => (
                                                            <li className={"w-full py-1.5"} key={item.uniqueUri}>
                                                                <Action {...item} button={false} showingActionButton={true} className={"block w-full"} />
                                                            </li>
                                                        ))}
                                                    </AnimateHeight>
                                                </Disclosure.Panel>
                                            </>
                                        ): null}
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </ul>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}


export default function Design2(props) {
    const context = useContext(WebsiteContext);
    return (
        <>
            {props?.settings?.showingContactBar && <ContactBar {...props} />}
            <Header {...props} className={classNames(
                "top-0 z-20",
                context.builder ? "" : "sticky",
                handleBackgroundColour(backgroundColour(props.settings?.backgroundColour), 0)
            )}>
                <div className={classNames(
                    "mx-auto max-w-7xl px-4 sm:px-8 flex justify-between items-center flex-row h-[60px] py-2 sm:py-4 sm:h-[72px]"
                )}>
                    <div className={"pr-2 h-full shrink-0"}>
                        <a href={"/"}
                           title={"Go Home"}
                           className={"h-full max-w-[144px] sm:max-w-[256px] flex items-center"}
                        >
                            {props.settings?.logo ? (
                                <Image
                                    pictureClassName={"h-full flex items-center"}
                                    alt={"Go Home"}
                                    width={320}
                                    imageWidth={props.settings?.image?.width}
                                    imageHeight={props.settings?.image?.height}
                                    src={props.settings?.logo}
                                    className={"h-full w-auto object-contain object-left"}
                                />
                            ) : (
                                <h1 className={"fit-text"}>{props.title}</h1>
                            )}
                        </a>
                    </div>
                    <DesktopNav {...props} context={context} />
                    <div className={classNames("flex items-center gap-4", hasStoreActions() && "flex-row-reverse sm:flex-row ml-2",
                        handleBackgroundColour(backgroundColour(props.settings?.backgroundColour), 0))}>
                        {props.items.length > 0 &&
                            <MobileNav {...props} />
                        }
                        <StoreActions {...props} context={context} />
                        {/*Hide the primary action on mobile if we have any store actions turned on*/}
                        {hasStoreActions() && <Action {...props.settings} className={"!hidden sm:!block text-base truncate shrink-0"} title={null}/>}
                        {/*Render the primary action as normal if there's no store actions*/}
                        {!hasStoreActions() && <Action {...props.settings} className={"text-base truncate shrink-0"} title={null}/>}
                    </div>
                </div>
            </Header>
        </>
    )
}