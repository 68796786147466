import React, {memo, useContext, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _set from "lodash/set";
import {nanoid} from "nanoid/non-secure";

import FormLayout from "../layouts/form-layout";
import FormContext from "../form-context";
import {
    applyDefaultValuesForExpressions,
    clearShowsWhenFieldValues,
    getValuesIncludingHidden,
    iterateFields,
    validate
} from "../utils";
import DividerAddButton from "./divider-add-button";
import _cloneDeep from "lodash/cloneDeep";
import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";
import _has from "lodash/has";
import _get from "lodash/get";
import _unset from "lodash/unset";
import axios from "axios";

const getOptions = (parentForm, additionalFields, controlId) => {
    // Array of id's used on the form
    const entityFieldIds = parentForm?.customFields?.find(f => f.id === 'custom-fields')?.fields?.map(f => f.id)?.filter(id => id !== controlId);
    const options = [
        {label: "Checkbox", value: "checkbox"},
        {label: "Currency", value: "currency"},
        {label: "Colour Picker", value: "colour-picker"},
        {label: "Date", value: "date"},
        {label: "Date & Time", value: "datetime-local"},
        {label: "Long Text", value: "textarea"},
        {label: "Rich Text", value: "rich-text"},
        {label: "Multi Selection", value: "checkbox-list"},
        {label: "Short Text", value: "text"},
        {label: "Hidden Field", value: "hidden"},
        {label: "Number", value: "number"},
        {label: "Signature Pad", value: "signature-pad"},
        {label: "Single Selection", value: "select"},
        {label: "Combo Box", value: "combobox"},
        {label: "Switch", value: "switch"},
        {label: "Gallery", value: "gallery"},
        {label: "Action", value: "action-input"},
        {label: "Address", value: "address"},
        {label: "Phone Number", value: "phone-number-input"},
        {label: "Form Divider", value: "divider", group: {label: "Layout"}},
        {label: "Text", value: "form-text", group: {label: "Layout"}},
        {label: "Step", value: "step", group: {label: "Layout"}},


        // Table and list components are not supported at the moment in the field builder. They require some more thought
        // before setting it up
        // {label: "Table", value: "table"},
        // {label: "List", value: "list"},

        {label: "Radio Group", value: "radio"},
        {label: "Image", value: "image-uploader"},
        {label: "Video", value: "video-uploader"},
        {label: "Document", value: "document-uploader"},
    ]

    // Add additional fields to options. Filter out any fields which have already been added.
    if (additionalFields) options.push(...additionalFields.filter(f => !entityFieldIds?.includes(f.id)))

    if (parentForm?.plugins) for (const key in parentForm?.plugins) if (parentForm?.plugins[key]?.ControlDisplaySettings?.()) options.push(parentForm?.plugins[key]?.ControlDisplaySettings());

    return options.filter(o => !!o)
}

const SETTINGS = (parentForm, controlType, additionalFields, context, controlId) => {
    const formSettings = {
        layout: "simple-stacked",
        fieldsets: [
            {
                fields: [
                    {
                        label: "Field Type",
                        type: "select",
                        name: "fieldId",
                        options: getOptions(parentForm, additionalFields, controlId),
                        placeholder: "Select a type",
                        required: true
                    },
                    {
                        type: "hidden",
                        name: "type",
                        required: true
                    }
                ]
            }
        ]
    }
    if (!controlType?.startsWith('plugins')) {
        formSettings.fieldsets[0].fields.push(
            {
                label: "Label",
                type: "text",
                name: "label",
                required: true,
                showsWhen: "type !== 'divider' && type !== 'form-text' && type !== 'step'"
            },
            {
                label: "Help Text",
                description: "Provide any information to help the form-filler complete this field",
                type: "text",
                name: "description",
                showsWhen: "type !== 'radio' && type !== 'checkbox-list' && type !== 'colour-picker' && type !== 'divider' && type !== 'form-text' && type !== 'step' && type !== 'hidden'"
            },
            {
                type: "checkbox-list",
                label: "Address fields",
                description: "Choose which fields to show for address",
                name: "fields",
                value: context?.values?.fields ? context.values.fields : ["postcode", "suburb", "addressLine1", "country", "state", "addressLine2"],
                options: [
                    {label: "Address Line 1", value: "addressLine1"},
                    {label: "Address Line 2", value: "addressLine2"},
                    {label: "Suburb", value: "suburb"},
                    {label: "State", value: "state"},
                    {label: "Postcode", value: "postcode"},
                    {label: "Country", value: "country"},
                ],
                showsWhen: "type === 'address'"
            },
            {
                label: "Categories",
                type: "table",
                name: "categories",
                showsWhen: "type === 'gallery'",
                sortable: true,
                editable: true,
                onItemAdd: (e, name, values, item) => {
                    return {...item, id: nanoid()};
                },
                fieldsets: [
                    {
                        fields: [
                            {
                                name: "id",
                                type: "hidden",
                            },
                            {
                                label: "Title",
                                name: "title",
                                type: "text",
                                required: true,
                            }
                        ]
                    }
                ],
            },
            {
                label: "Custom Field Name",
                type: "switch",
                name: "customFieldName",
                showsWhen: "type !== 'divider'"
            },
            {
                type: "text",
                name: "fieldName",
                placeholder: 'Custom field name',
                required: true,
                showsWhen: 'customFieldName === true'
            },
            {
                label: "Mark as Required",
                description: "The form-filler must complete this field",
                type: "switch",
                flipLayout: true,
                small: true,
                name: "required",
                showsWhen: "type !== 'checkbox-list' && type !== 'colour-picker' && type !== 'checkbox' && type !== 'divider' && type !== 'form-text' && type !== 'step'"
            },
            {
                type: 'select',
                label: 'Size',
                name: 'rows',
                options: [
                    {label: 'Small', value: 4},
                    {label: 'Medium', value: 6},
                    {label: 'Large', value: 8},
                ],
                value: 6,
                showsWhen: "type === 'textarea'",
                required: true
            },
            !context?.values?.href ? {
                label: "Options",
                type: "table",
                name: "options",
                showsWhen: "type === 'select' || type === 'checkbox-list' || type === 'combobox'",
                sortable: true,
                editable: true,
                fieldsets: [
                    {
                        fields: [
                            {
                                label: "Option",
                                name: "label",
                                type: "text",
                                required: true
                            },
                            {
                                label: "Custom value",
                                type: "switch",
                                name: "customValue",
                            },
                            {
                                label: "Value",
                                name: "value",
                                type: "text",
                                required: true,
                                showsWhen: "customValue === true"
                            }
                        ]
                    }
                ],
                ignoreFields: ['customValue', 'value'],
                required: true
            } : null,
            !context?.values?.href ? {
                label: "Options",
                type: "table",
                name: "options",
                showsWhen: "type === 'colour-picker'",
                sortable: true,
                editable: true,
                fieldsets: [
                    {
                        fields: [
                            {
                                label: "Colour",
                                name: "colour",
                                type: "color",
                                required: true
                            }, {
                                label: "Option",
                                name: "label",
                                type: "text",
                                required: true
                            },
                            {
                                label: "Custom value",
                                type: "switch",
                                name: "customValue",
                            },
                            {
                                label: "Value",
                                name: "value",
                                type: "text",
                                required: true,
                                showsWhen: "customValue === true"
                            }
                        ]
                    }
                ],
                ignoreFields: ['customValue', 'value'],
                required: true
            } : null,
            {
                label: "Options",
                type: "select",
                name: "style",
                value: "list",
                options: [
                    {
                        label: "List",
                        value: "list"
                    },
                    {
                        label: "Cards",
                        value: "cards"
                    },
                ],
                showsWhen: "type === 'radio'",
                required: true
            },
            !context?.values?.href ? {
                label: "Options",
                type: "table",
                name: "options",
                showsWhen: "type === 'radio'",
                sortable: true,
                editable: true,
                ignoreFields: ['customValue', 'value'],
                fieldsets: [
                    {
                        fields: [
                            {
                                label: "Image",
                                type: "image-uploader",
                                className: "max-w-xs",
                                name: "image",
                                pintura: false,
                                unsplash: false
                            },
                            {
                                label: "Option",
                                name: "label",
                                type: "text",
                                required: true
                            },
                            {
                                label: "Description",
                                name: "description",
                                type: "text"
                            },
                            {
                                label: "Custom value",
                                type: "switch",
                                name: "customValue",
                            },
                            {
                                label: "Value",
                                name: "value",
                                type: "text",
                                required: true,
                                showsWhen: "customValue === true"
                            }
                        ]
                    }
                ],
                required: true
            } : null,
            {
                type: "rich-text",
                label: "Text to display",
                description: "A way to add text to your form without asking for an answer",
                name: "value",
                showsWhen: "type === 'form-text'",
                required: true,
                rows: 10
            },
            {
                type: "text",
                label: "Title",
                description: "Step title",
                name: "title",
                showsWhen: "type === 'step'",
            },
            {
                type: "rich-text",
                label: "Text",
                description: "Step text",
                name: "text",
                showsWhen: "type === 'step'",
            })
    } else {
        const currentPlugin = parentForm.pluginControls[controlType];
        if (currentPlugin?.ControlConfigurationFields?.()) formSettings.fieldsets[0].fields.push(...currentPlugin.ControlConfigurationFields());
    }

    if (parentForm.id === "product_type_form") formSettings.fieldsets[0].fields.push({
        label: "Hide on product detail page",
        type: "switch",
        flipLayout: true,
        small: true,
        name: "hideOnProductDetailPage",
    }, {
        label: "Hide from filters",
        type: "switch",
        flipLayout: true,
        small: true,
        name: "hideFromFilters",
    })

    formSettings.fieldsets[0].fields.push({
        label: "Auto-select single value",
        description: "If there is only one value available it will be automatically selected",
        type: "switch",
        showsWhen: "type === 'combobox'",
        flipLayout: true,
        small: true,
        name: "autoSelectIfSingle",
    })

    formSettings.fieldsets[0].fields = formSettings.fieldsets[0].fields.filter(field => !!field);
    return formSettings
}

const getSettings = (parentFormContext, controlType, props, additionalFields, context, controlId) => {
    const settings = SETTINGS(parentFormContext, controlType, additionalFields, context, controlId);
    if (!_isObject(props.extendable)) return settings;

    if (props.extendable.canChangeFieldName === false) {
        settings.fieldsets[0].fields = settings.fieldsets[0].fields.filter(field => !field.name.toLowerCase()?.includes('fieldname'));
    }

    if (_isArray(props.extendable.controls)) {
        // Array of id's used on the form, excluding the current one being edited
        let entityFieldIds = parentFormContext?.customFields?.find(f => f.id === 'custom-fields')?.fields?.map(f => f.id)?.filter(id => id !== controlId);
        const field = settings.fieldsets[0].fields.find(field => field.name === 'fieldId');
        const typeField = settings.fieldsets[0].fields.find(field => field.name === 'type');

        if (field) {
            field.options = getOptions(parentFormContext).filter(option => props.extendable.controls?.includes(option.value));
            // Add additional fields to options. Filter out any fields which have already been added.
            if (additionalFields) field.options = [...field.options, ...additionalFields.filter(f => !entityFieldIds?.includes(f.id))];
            field.value = props.extendable.defaultDateType || "text";
            typeField.value = props.extendable.defaultDateType || "text";
        }
    }

    if (_isObject(props.extendable.copy)) {
        const fieldsetCopy = props.extendable.copy.fieldset || {};
        settings.fieldsets.forEach(fieldset => {
            if (fieldsetCopy[fieldset.id]) fieldset.label = fieldsetCopy[fieldset.id] || fieldset.label;
        });

        const fieldCopy = props.extendable.copy.field || {};
        iterateFields(settings.fieldsets, (field) => {
            if (_has(fieldCopy, field.name)) field.label = _get(fieldCopy, field.name) || field.label
        });
    }

    return settings;
}

const mapFields = (fields, groupLabel, entity) => fields.map(f => {
    delete f.fieldId;
    return entity ? {
        ...f,
        name: `${entity}.${f.id}`,
        id: `${entity}.${f.id}`,
        value: `${entity}.${f.id}`,
        group: {label: groupLabel},
    } : f
});


/**
 * Manages the edit state of a field.  The usage of this component is complicated in a sense that we a rendering
 * this component in a Fields component and using a FormLayout component which inturn uses a Fields component to render
 * the settings form of the field.
 *
 * It will take the nearest form context and create a new one to manage this field settings form instance. When this form
 * is submitted it will update the field. Depending on if this component was rendered using the fieldset builder or just
 * rendered because the fieldset is expandable but the fieldset is not editable will determine who updates the fieldset
 * in the form context.
 *
 * When this component is rendered using the FieldsetBuilder its the FieldsetBuilder's responsibility to update the
 * fieldset. This is because the fieldset is being managed by the FieldsetBuilder ranther then just rendered. If
 * rendered by itself then the parent manages updating the fieldset.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FieldBuilder = memo((props) => {
    // Get a reference to the parent form context. When this component is rendered in a form layout the origin of the
    // render could come from the fieldset build or form component. This will return the nearst parent form context. 
    const parentFormContext = useContext(FormContext);
    const formRef = useRef();
    const [editing, setEditing] = useState(props.editing);
    const [controlType, setControlType] = useState(props?.field?.type);
    const controlId = useMemo(() => props?.field?.name, [props?.field?.name]);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [settings, setSettings] = useState(() => getSettings(parentFormContext, controlType, props, additionalFields, context, controlId));

    useEffect(() => {
        setSettings(getSettings(parentFormContext, controlType, props, additionalFields, context, controlId))
    }, [props.extendable, controlType, additionalFields])
    
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const newFields = [];
        if (props?.extendable?.additionalFields) {
            (async () => {
                for (let fields of props.extendable.additionalFields) {
                    fields.userDefinedFieldsets?.[0]?.fields
                        ? newFields.push(...mapFields(fields.userDefinedFieldsets[0].fields, fields.groupLabel, fields.entity))
                        : await axios.get(fields.href, {cancelToken: cancelToken.token})
                            .then(({data}) => {
                                newFields.push(...mapFields(data, fields.groupLabel, fields.entity))
                            })
                            .catch((err) => {
                                if (!axios.isCancel(err)) console.log(err)
                            })
                }
                setAdditionalFields(newFields);
            })();
        }
        return () => cancelToken.cancel();
    }, [props?.extendable?.additionalFields])

    // Setup a form context for the field settings form
    const [context, setContext] = useState(() => {
        return {
            ...parentFormContext,
            values: props.editing ? {...props.field} : getValuesIncludingHidden(settings.fieldsets, settings.hiddenFields),
            errors: {},
            original: null,
            validate: (ref) => {
                return validate((ref || formRef).current, setContext);
            }
        }
    });

    if (context?.values && !context?.values?.fieldId && context?.values?.type) {
        setContext(p => ({...p, values: {...p.values, fieldId: p.values.type}}))
    }

    useEffect(() => {
        if (formRef.current && editing) {
            formRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
        }
    }, [formRef, editing]);

    return (
        <div className={classNames(
            "flex w-full",
            !editing ? "justify-center items-center cursor-pointer" : null,
            editing ? "flex-col" : null,
        )}>
            {!editing ? (
                <DividerAddButton label={"Add Field"} onClick={() => {
                    setEditing(true)
                }}/>
            ) : null}
            {editing ? (
                <FormContext.Provider value={context}>
                    <FormLayout
                        ref={formRef}
                        {...settings}
                        className={"w-full bg-panels-100 rounded-md p-4"}
                        builder={true}
                        actions={props.editing ? [
                            {label: "Done", primary: true},
                            {label: "Remove", destructive: true},
                            {label: "Cancel", back: true}
                        ] : [
                            {label: "Done", primary: true},
                            {label: "Cancel", back: true}
                        ]}
                        onFieldChange={(e, name, value) => {
                            const options = settings?.fieldsets?.[0]?.fields?.find(field => field.name === 'fieldId')?.options;
                            const option = options?.find(o => o?.value === value);
                            if (name === 'fieldId') setControlType(options.type || value);

                            setContext(previous => {
                                let values = _cloneDeep(previous.values);
                                const errors = _cloneDeep(previous.errors);

                                _set(values, name, value);
                                _set(errors, name, false);

                                if (name === 'fieldId') {
                                    _set(values, 'type', (options.type || value))
                                    if (option.type) values = {...values, ...option};
                                }

                                if (e.target?.options) {
                                    for (let i = 0; i < e.target.options.length; i++) {
                                        if (e.target.options[i].value === value && e.target.options[i].id) {
                                            values.id = e.target.options[i].id;
                                        }
                                    }
                                }

                                // Apply new defaults of fields that have expressions
                                applyDefaultValuesForExpressions(settings.fieldsets, name, value, values);

                                if (e.target?.setCustomValidity) {
                                    e.target.setCustomValidity('');
                                }

                                return {...previous, values};
                            });
                        }}
                        onActionClick={(e, action) => {
                            e.preventDefault();
                            e.stopPropagation();

                            let values = null;
                            if (action.primary) {
                                if (!validate(formRef.current, setContext)) {
                                    return;
                                }

                                const id = context.values.id || `cf_${nanoid()}`;
                                values = {
                                    ...context.values,
                                    id,
                                    name: `${props.userDefinedFieldsetsScope ? `${props.userDefinedFieldsetsScope}.` : ''}${id}`
                                };
                                if (props?.extendable?.disablePreview) values.disabled = true;

                                // Clear fields where they are hidden by showsWhen expressions
                                clearShowsWhenFieldValues(settings.fieldsets, values);

                                // Handle fields with options that don't have a custom value 
                                // and set the value as the label
                                if (_isArray(values.options)) {
                                    values.options = values.options.map(option => {
                                        if (!(_isObject(option) && (option.value === undefined || option.value === null))) return option;
                                        return {...option, value: option.label};
                                    });
                                }

                                // Handle when the field is a radio group and it's not mandatory to allow selected
                                // option to be deselectable
                                if (values.type === 'radio' && !values.required) {
                                    values.deselectable = true;
                                } else {
                                    _unset(values, 'deselectable');
                                }

                                // Handle when the data type is a divider
                                if (values.type === 'divider') {
                                    values.divider = true;
                                    _unset(values, 'type');
                                }
                            }

                            setEditing(false);
                            setContext(previous => ({
                                ...previous,
                                values: props.editing ? {...props.field} : getValuesIncludingHidden(settings.fieldsets, settings.hiddenFields),
                                errors: {}
                            }));

                            props.onActionClick(e, action, values);
                        }}
                    />
                </FormContext.Provider>
            ) : null}
        </div>
    );
});

FieldBuilder.propTypes = {
    className: PropTypes.string,
    fieldset: PropTypes.object,
    field: PropTypes.object,
    editing: PropTypes.bool,
    onActionClick: PropTypes.func,
};

FieldBuilder.defaultProps = {
    onActionClick: () => {
    },
};

export default FieldBuilder;