import React, {memo, useContext} from "react";
import Currency from "@autocx/forms/src/controls/currency";
import {WebsiteContext} from "../../index";
import classNames from "classnames";
import {aspectRatio} from "../../utils/block-utils";
import {Image} from "../../components/image";

export const Design2 = memo(({product, id, ...props}) => {
    const context = useContext(WebsiteContext)

    const imageUrl = product.images && product.images.length > 0
        ? product?.imagesState?.[0]?.__cx__?.key || product?.images?.[0]
        : 'https://dr1k2g3wmnols.cloudfront.net/images/image-placeholder.png';

    return (
        <a
            key={id}
            href={`product/${product.uuid}/${product.title.toLowerCase().trim().replace(/[^a-z0-9-\/\s]/g, '').replace(/\s+/g, '-')}`}
            className="group relative flex flex-col overflow-hidden rounded-theme border border-gray-200 bg-white cursor-pointer">
            <div className={classNames(
                aspectRatio(props),
                "overflow-hidden relative"
            )}>
                <Image
                    src={imageUrl}
                    alt={product.title}
                    className="h-full w-full object-cover object-center sm:h-full sm:w-full "
                />
                {product?.vehicleType ?
                    <div className={classNames(
                        product?.vehicleType?.toLowerCase() === 'new' ? 'bg-[rgba(126,211,33,0.75)]' : 'bg-[rgba(128,147,159,0.75)]',
                        "absolute left-2 bottom-2 px-2 py-1 text-sm rounded-theme backdrop-blur-sm text-white"
                    )}>{product.vehicleType}</div>
                    : null}
            </div>
            <div className="flex flex-1 flex-col p-4">
                <h6 className="text-sm font-bold text-gray-900 !mb-1">
                    {product.title}
                </h6>
                <div className="grid grid-cols-2 gap-2 my-2">
                    <div className="flex items-baseline">
                        <svg className="w-3 h-3 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <g>
                                <path
                                    d="M472.61261,438.47888h-30.443a22.59965,22.59965,0,0,0,.23462-3.22339V408.64313A22.74381,22.74381,0,0,0,419.72705,385.966H394.76257c.00342-.16095.01233-.32068.01233-.48242V358.87122a22.7438,22.7438,0,0,0-22.67712-22.67712H304.63843l72.22369-162.21716,8.28015,3.62134a50.27222,50.27222,0,0,0,20.22046,4.19885c21.91376,0,43.72949-13.81848,53.95807-36.45758v-.00012c13.425-29.71405,2.01282-63.8205-25.36029-75.7923L342.01355,29.334A50.26558,50.26558,0,0,0,321.793,25.13519c-21.91376.00134-43.72943,13.81842-53.95795,36.45758v.00012c-13.425,29.714-2.01288,63.8205,25.36023,75.79224l8.87506,3.88153L215.283,336.19409h-75.3808a22.7438,22.7438,0,0,0-22.67712,22.67712v26.61237c0,.16174.00891.32147.01233.48242H92.27295a22.74381,22.74381,0,0,0-22.67719,22.67712v26.61237a22.59965,22.59965,0,0,0,.23462,3.22339h-30.443a22.74381,22.74381,0,0,0-22.67712,22.67719v25.89911a22.74385,22.74385,0,0,0,22.67712,22.67719H472.61261a22.74385,22.74385,0,0,0,22.67712-22.67719V461.15607A22.74381,22.74381,0,0,0,472.61261,438.47888ZM304.28717,78.06213c4.01257-8.88123,11.88861-12.92664,17.50647-12.927h.00226a10.24824,10.24824,0,0,1,4.18951.84711l91.94684,40.21313a11.43573,11.43573,0,0,1,5.82867,6.6015,21.084,21.084,0,0,1-.89227,16.07324c-4.01288,8.88177-11.88751,12.927-17.50586,12.927a10.25381,10.25381,0,0,1-4.19238-.84711l-91.9469-40.21313a11.43544,11.43544,0,0,1-5.82861-6.6015A21.08349,21.08349,0,0,1,304.28717,78.06213Z"/>
                            </g>
                        </svg>
                        <span className="ml-1 text-gray-900 text-sm">{product.transmission || '–'}</span>
                    </div>
                    <div className="flex items-baseline">
                        <svg className="w-3 h-3 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <g>
                                <path
                                    d="M502.73881,208.09073H491.56827a8.40155,8.40155,0,0,0-8.39064,8.37932V392.016a8.40031,8.40031,0,0,0,8.39064,8.39064h11.17054a8.40156,8.40156,0,0,0,8.3793-8.39064V216.46438A8.39063,8.39063,0,0,0,502.73881,208.09073Z"/>
                                <path
                                    d="M9.26121,201.00492a8.39187,8.39187,0,0,0-8.37932,8.3793v189.78a8.40036,8.40036,0,0,0,8.37932,8.385H32.0391a8.39308,8.39308,0,0,0,8.37932-8.385V209.4296a8.39188,8.39188,0,0,0-8.37932-8.37932H9.26121Z"/>
                                <path
                                    d="M327.75465,433.107c18.68751-7.25035,37.26156-14.722,55.90938-21.96664a16.4372,16.4372,0,0,1,5.37251-.90773q20.69583-.08512,41.34629-.05674c14.29077,0,23.4757-9.1906,23.4757-23.63455V339.27787a6.04938,6.04938,0,0,1,6.06465-6.06465l25.95688-.15318c4.73145,0,5.00945-1.83245,5.15692-5.10589l.11916-57.44126a4.93924,4.93924,0,0,0-4.947-4.94135h-26.388a6.11476,6.11476,0,0,1-6.1214-6.12707l.20425-57.305c.16452-8.4871-3.26774-14.33618-9.77492-19.01657-11.17054-8.05594-44.08641-31.41818-49.46464-34.85615a21.08389,21.08389,0,0,0-12.24275-3.33017l-56.77171-.05673a8.75138,8.75138,0,0,1-8.75374-8.75374v-11.77a8.87,8.87,0,0,1,8.86154-8.86154h51.45592a8.87367,8.87367,0,0,0,8.86152-8.86154V86.37018a8.87367,8.87367,0,0,0-8.86152-8.86154H206.37653a8.86641,8.86641,0,0,0-8.86154,8.86154V106.633a8.87,8.87,0,0,0,8.86154,8.86154h55.68814a8.87489,8.87489,0,0,1,8.86721,8.86154v12.04236a8.72919,8.72919,0,0,1-8.82183,8.74807l-56.22708-.20991c-10.75072-.32337-19.54984,3.33017-27.71925,9.236-11.92506,8.646-23.62887,17.56423-35.33836,26.48249a16.8258,16.8258,0,0,1-10.36493,3.43228c-11.27265-.11347-35.55664-.11347-46.835,0-14.01848.1078-23.08992,9.12819-23.20339,23.20339l.15885,39.15076c0,3.32449-12.6891,6.06465-16.07032,6.06465H41.25154a11.53218,11.53218,0,0,0-11.55064,11.55063v92.03645A11.52613,11.52613,0,0,0,41.25154,367.6325h5.10021c3.33017,0,16.06465,2.69476,16.06465,6.07032,0,8.90692-.05673,25.5067,0,35.11143,0,8.92393-.15885,25.67691,16.16862,25.67691H320.61209A18.77086,18.77086,0,0,0,327.75465,433.107Z"/>
                            </g>
                        </svg>
                        <span className="ml-1 text-gray-900 text-sm">{product.enginePower || '–'} HP</span>
                    </div>
                    <div className="flex items-baseline">
                        <svg className="w-3 h-3 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <g>
                                <path
                                    d="M378.9649,181.41732h45.35431a22.74383,22.74383,0,0,0,22.67718-22.67719V22.67714A22.74387,22.74387,0,0,0,424.31921,0H378.9649a22.74391,22.74391,0,0,0-22.67719,22.67719V68.03145H176.9964V22.67714A22.74387,22.74387,0,0,0,154.31921,0H108.9649A22.74391,22.74391,0,0,0,86.28772,22.67714v136.063a22.74387,22.74387,0,0,0,22.67719,22.67719h45.35431a22.74383,22.74383,0,0,0,22.67719-22.67719V113.38582h68.24249V398.61416H176.9964V353.25985a22.74383,22.74383,0,0,0-22.67719-22.67719H108.9649a22.74387,22.74387,0,0,0-22.67719,22.67719v136.063A22.74385,22.74385,0,0,0,108.9649,512h45.35431a22.74381,22.74381,0,0,0,22.67719-22.67712V443.96846H356.28772v45.35437A22.74385,22.74385,0,0,0,378.9649,512h45.35431a22.74381,22.74381,0,0,0,22.67718-22.67712v-136.063a22.74383,22.74383,0,0,0-22.67718-22.67719H378.9649a22.74387,22.74387,0,0,0-22.67719,22.67719v45.35431H290.59326V113.38582h65.69446v45.35431A22.74387,22.74387,0,0,0,378.9649,181.41732Z"/>
                            </g>
                        </svg>
                        <span className="ml-1 text-gray-900 text-sm">{product.axleConfiguration || '–'}</span>
                    </div>
                    <div className="flex items-baseline">
                        <svg className="w-3 h-3 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <g>
                                <circle cx="255.99997" cy="373.68666" r="25.33336"/>
                                <path
                                    d="M255.99991,48.71655C115.04727,48.71655.882,162.8819.882,303.83466A253.95036,253.95036,0,0,0,57.008,463.28345H454.9921a253.95036,253.95036,0,0,0,56.126-159.44879C511.11807,162.8819,396.95279,48.71655,255.99991,48.71655ZM64.66129,335.72443a31.88977,31.88977,0,1,1,31.88989-31.88977A31.98347,31.98347,0,0,1,64.66129,335.72443Zm63.77979-127.55908a31.88977,31.88977,0,1,1,31.88965-31.88977A31.98352,31.98352,0,0,1,128.44107,208.16534Zm127.55884-127.559A31.88974,31.88974,0,1,1,224.11026,112.496,31.98347,31.98347,0,0,1,255.99991,80.60632ZM318.9147,197.21869,285.79385,382.99982a31.27284,31.27284,0,0,1-38.0603,22.26965v-.00006A31.27251,31.27251,0,0,1,225.464,367.20917l62.14307-178.18488c4.34229-16.5907,14.97363-26.58014,23.54907-26.06775v.00006C318.71646,166.58051,323.257,180.628,318.9147,197.21869ZM383.559,208.16534a31.88977,31.88977,0,1,1,31.88989-31.88977A31.98347,31.98347,0,0,1,383.559,208.16534Zm63.77954,127.55908a31.88977,31.88977,0,1,1,31.88989-31.88977A31.98347,31.98347,0,0,1,447.33853,335.72443Z"/>
                            </g>
                        </svg>
                        <span className="ml-1 text-gray-900 text-sm">{product.odometer || '–'} km</span>
                    </div>
                    <div className="flex items-baseline">
                        <svg className="w-3 h-3 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <g>
                                <path fill="none"
                                      d="M110.56091,187.42312,56.95313,241.0309a20.0757,20.0757,0,0,0-2.47418,6.0481v8.24731H160.045V184.94893H116.609A11.42931,11.42931,0,0,0,110.56091,187.42312Z"/>
                                <path fill="none"
                                      d="M124.85632,360.89248a35.18869,35.18869,0,1,0,24.87952,60.06818,35.18869,35.18869,0,0,0-24.87952-60.06818Z"/>
                                <path fill="none"
                                      d="M371.17725,431.26986a35.19772,35.19772,0,1,0-24.87952-10.3092A33.90266,33.90266,0,0,0,371.17725,431.26986Z"/>
                                <g>
                                    <path
                                        d="M193.58923,306.04989s-1.13562-116.48456-1.13562-143.55286c0-10.28052-1.498-9.73376-1.95361-10.28052-3.91077-.33331-7.91077-.33331-7.91077-.33331l-74.88031-1.03284a54.41387,54.41387,0,0,0-7.59467,2.4837,41.79214,41.79214,0,0,0-14.02045,8.79718L31.66125,216.5638a41.79088,41.79088,0,0,0-8.79718,14.02051,42.86667,42.86667,0,0,0-3.57385,15.94489V360.89248a16.90846,16.90846,0,0,0-12.371,5.22333,17.26156,17.26156,0,0,0,0,24.74207,16.90863,16.90863,0,0,0,12.371,5.22327H54.47894q0,29.14069,20.61835,49.759t49.759,20.61841q29.14069,0,49.759-20.61841t20.61841-49.759h105.5661q0,29.14069,20.61841,49.759t49.759,20.61841q29.14069,0,49.759-20.61841t20.61841-49.759h52.78308a17.83922,17.83922,0,0,0,17.59436-17.5943v-60.437H205.58923A12.03527,12.03527,0,0,1,193.58923,306.04989ZM149.73584,420.96065a35.17585,35.17585,0,0,1-49.759,0,35.18869,35.18869,0,0,1,24.87952-60.06818,35.18491,35.18491,0,0,1,24.87952,60.06818ZM160.045,255.32631H54.47894V247.079a20.0757,20.0757,0,0,1,2.47418-6.0481l53.60779-53.60779a11.42931,11.42931,0,0,1,6.0481-2.47418h43.436ZM346.29773,371.20168a35.17592,35.17592,0,1,1-10.30914,24.87946A33.90276,33.90276,0,0,1,346.29773,371.20168Z"/>
                                    <path
                                        d="M243.7959,296.60225h18.04492v-32H247.7959v-8.33691h-32v12.33691A28.03145,28.03145,0,0,0,243.7959,296.60225Z"/>
                                    <rect x="215.7959" y="182.40694" width="32" height="49.23926"/>
                                    <rect x="215.7959" y="108.54757" width="32" height="49.23926"/>
                                    <path
                                        d="M247.7959,69.83077h8.28418v-32H243.7959a28.03145,28.03145,0,0,0-28,28V83.92843h32Z"/>
                                    <path
                                        d="M480,264.60225H465.95508v32H484a28.03145,28.03145,0,0,0,28-28V256.26534H480Z"/>
                                    <rect x="480" y="182.40694" width="32" height="49.23926"/>
                                    <rect x="480" y="108.54757" width="32" height="49.23926"/>
                                    <path
                                        d="M484,37.83077H471.71582v32H480V83.92843h32V65.83077A28.03145,28.03145,0,0,0,484,37.83077Z"/>
                                    <rect x="343.29816" y="37.83071" width="42.51971" height="32"/>
                                    <rect x="406.71582" y="37.83071" width="42.51971" height="32"/>
                                    <rect x="279.43976" y="37.83071" width="42.51971" height="32"/>
                                    <rect x="343.29816" y="264.60238" width="42.51971" height="32"/>
                                    <rect x="406.71582" y="264.60238" width="42.51971" height="32"/>
                                    <rect x="279.43976" y="264.60238" width="42.51971" height="32"/>
                                </g>
                            </g>
                        </svg>
                        <span className="ml-1 text-gray-900 text-sm">{product.categoryOrApplication || '–'}</span>
                    </div>
                </div>
                <div className="flex flex-1 flex-col justify-end">
                    {!product?.hidePriceOnWebsite && <>
                        <div className={"border-t mt-2 mb-3"}></div>
                        <div className={"text-gray-900"}>
                            {product.discountedSellingPrice ? (
                                <span className={"font-semibold mr-1"}>
                                <Currency.DisplayValue
                                    currency={context.currency}
                                    value={product.discountedSellingPrice}
                                    emptyPlaceholder={""}/>
                            </span>
                            ) : null}
                            <span
                                className={classNames(product.discountedSellingPrice ? "line-through opacity-80 text-sm" : "font-semibold")}>
                            {product.sellingPrice
                                ? <Currency.DisplayValue
                                    currency={context.currency}
                                    value={product.sellingPrice}
                                    emptyPlaceholder={""}/>
                                : "Price on Application"}
                        </span>
                            {product.sellingPriceDisclaimer ? (
                                <span className="ml-1 text-gray-500 text-sm">
                                {product.sellingPriceDisclaimer}
                            </span>
                            ) : null}
                        </div>
                    </>}
                </div>
            </div>
        </a>
    )
})

export default Design2;