import classNames from "classnames";
import {Gallery, Item} from 'react-photoswipe-gallery'

import Block from '../block'
import {
    backgroundColour,
    marginForTextAlignment,
    textAlignment,
    aspectRatio,
    setTitleDecorationByClass,
    setTitleDecorationByElement, objectFit
} from "../../utils/block-utils";
import useCdn from "../../hooks/cdn-hook";
import Action from "../../components/action";
import {WebsiteContext} from "../../index";
import {useContext} from "react";
import {Image} from "../../components/image";

const galleryOptions = {
    shareEl: false,
    showHideOpacity: true,
    maxSpreadZoom: 4,
    fullscreenEl: false,
    getDoubleTapZoom: function (isMouseClick, item) {
        if (isMouseClick) {
            return 2
        } else {
            return item.initialZoomLevel < 0.7 ? 1 : 4;
        }
    }
}

export default function Design1(props) {
    const context = useContext(WebsiteContext);

    return (
        <Block {...props} className={classNames(
            props.className,
            !props.values?.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
        )}>
            <div className={classNames(
                "mx-auto max-w-7xl section flex flex-col items-center",
                textAlignment(props),
                props.values?.options?.inset ? 'rounded-theme' : null,
                props.values?.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
            )}>
                <div className={classNames('w-full')}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative rich-text", setTitleDecorationByClass(props.values?.options?.titleDecoration))} dangerouslySetInnerHTML={{__html: props.values.title}}/>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                             className={classNames("rich-text", marginForTextAlignment(textAlignment(props)))}
                        />
                    ) : null}
                </div>
                <div className={"w-full"}>
                    <Gallery options={galleryOptions}>
                        <div className={"mb-4 grid grid-cols-2 gap-x-4 gap-y-4 sm:gap-x-8 sm:gap-y-8 sm:grid-cols-4"}>
                            {props.values?.items?.map((item, i) => {
                                item.imageState = item.imageState || item.sourceState
                                item.image = item.image || item.source
                                return (
                                    <Item
                                        original={useCdn(item.imageState?.__cx__?.key || item.image)}
                                        thumbnail={useCdn(item.imageState?.__cx__?.key || item.image)}
                                        width={global.innerWidth}
                                        height={global.innerHeight}
                                        title={item.caption}
                                        key={i}>
                                        {({ref, open}) => (
                                            item.image ? (
                                                <div
                                                    className={classNames("hover:cursor-pointer hover:opacity-75 relative rounded-theme overflow-hidden", aspectRatio(props))}>
                                                    <Image ref={ref} onClick={context.builder ? null : open}
                                                           id={item.imageState?.__cx__?.id ? `image_${item.imageState?.__cx__?.id}` : null}
                                                           alt={item.altText}
                                                           src={item.imageState?.__cx__?.key || item.image}
                                                           className={classNames(
                                                               " w-full h-full object-center rounded-none",
                                                               objectFit(props.values.options?.imagePresentation)
                                                           )}/>
                                                </div>
                                            ) : null
                                        )}
                                    </Item>
                                )
                            })
                            }
                        </div>
                    </Gallery>
                    <Action className={"mt-6"} {...props.values} title={null}/>
                </div>
            </div>
        </Block>
    )
}
