import Design1 from './design-1';
import {useContext, useEffect, useState} from "react";
import {WebsiteContext} from "../../index";

export default function TextMediaBlock(props) {
    const context = useContext(WebsiteContext);
    const [imageWidth, setImageWidth] = useState(1184);
    
    let DesignBlock;

    switch (props.values?.design) {
        case 0 :
            DesignBlock = Design1;
            break;
        default:
            DesignBlock = Design1;
    }
    useEffect(() => {
        window.innerWidth < context.breakpoint
            ? setImageWidth(412)
            : setImageWidth(568)
    }, []);

    return (
        <DesignBlock {...props} imageWidth={imageWidth}/>
    )
};