import Block from '../block'
import {listColumns} from "./design-1";
import {
    aspectRatio,
    backgroundColour,
    backgroundPattern,
    objectFit,
    setTitleDecorationByClass,
    setTitleDecorationByElement,
    textAlignment
} from "../../utils/block-utils";
import classNames from "classnames";
import Action from "../../components/action";
import {Image} from "../../components/image";

export default function Design2(props) {
    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values?.options?.inset ? backgroundPattern(props) : null,
                   !props.values?.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <div className={classNames(
                "mx-auto max-w-7xl section flex flex-col items-center",
                textAlignment(props),
                props.values?.options?.inset ? 'rounded-theme' : null,
                props.values?.options?.inset ? backgroundPattern(props) : null,
                props.values?.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null
            )}>
                <div className={"w-full"}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative rich-text", setTitleDecorationByClass(props.values?.options?.titleDecoration))}
                                dangerouslySetInnerHTML={{__html: props.values.title}}/>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                </div>
                <div className={classNames("relative w-full overflow-x-auto",
                    props.values.options?.listItemsMobileDisplay === "stack" ? null : "snap-mandatory snap-x sm:snap-none"
                )}>
                    <ul className={classNames(
                        "mb-4 lg:space-x-0 lg:grid lg:gap-x-8 lg:gap-y-12",
                        props.values.options.listItemsMobileDisplay === "stack" ? "block space-y-6 sm:space-y-0" : "inline-flex space-x-4",
                        listColumns(props)
                    )}>
                        {props.values.items?.map((item, i) => (
                            <li key={i} className={classNames(
                                "inline-flex flex-col snap-start rounded-theme overflow-hidden lg:w-auto",
                                props.values.options.listItemsMobileDisplay === "stack" ? "w-full" : "w-64",
                                props.values.options?.backgroundColour === 1 ? 'section-grey' : 'section-white'
                            )}>
                                <div className="group flex flex-1 flex-col relative h-full rounded-theme">
                                    <div className={classNames(
                                        "w-full overflow-hidden relative",
                                        aspectRatio(props)
                                    )}>
                                        {item.image ? (
                                            <Image
                                                id={item.imageState?.__cx__?.id ? `image_${item.imageState?.__cx__?.id}` : null}
                                                src={item.imageState?.__cx__?.key || item.image}
                                                key={item.image + item.imageState + props.values?.options?.imagePresentation}
                                                alt={item?.altText}
                                                width={props.imageWidth}
                                                className={classNames(
                                                    " w-full h-full object-center !rounded-b-none",
                                                    objectFit(props.values.options?.imagePresentation),
                                                    item.action && item.action.type && !['button', 'buttonFull', 'link'].includes(props.values?.options?.actionStyle) ? 'group-hover:opacity-75' : ''
                                                )}
                                            />
                                        ) : null}
                                    </div>
                                    <div className={classNames("flex flex-col flex-1 p-4", textAlignment(props))}>
                                        {item.title && <h6>
                                            {(!props.values?.options?.actionStyle || props.values?.options?.actionStyle === 'noStyle') &&
                                                <Action {...item}
                                                        action={{...item.action, label: undefined}}
                                                        itemActionStyle={props.values?.options?.actionStyle}
                                                        title={null}
                                                        button={false}
                                                        defaultHref={'#'}>
                                                    <span className="absolute inset-0"/>
                                                </Action>
                                            }
                                            <span className={"rich-text"}
                                                  dangerouslySetInnerHTML={{__html: item.title}}/>
                                        </h6>
                                        }
                                        {item.text ? (
                                            <div dangerouslySetInnerHTML={{__html: item.text}}
                                                 className="mt-1 rich-text"/>
                                        ) : null}
                                        {props.values?.options?.actionStyle !== 'noStyle' && props.values?.options?.actionStyle &&
                                            <div className={"mt-auto"}>
                                                <Action className={"mt-3 mb-2"} {...item}
                                                        itemActionStyle={props.values?.options?.actionStyle}
                                                        title={null}/>
                                            </div>
                                        }
                                        {item?.detailsPopup?.label &&
                                            <div className={"mt-auto pt-4"}>
                                                <button className={"opacity-80 text-sm hover:opacity-100"}
                                                        onClick={() =>
                                                            props.handleOpenModal(
                                                                {
                                                                    label: item.detailsPopup.label,
                                                                    text: item.detailsPopup.text
                                                                }
                                                            )
                                                        }
                                                >
                                                    {item?.detailsPopup?.label}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <Action className={"mt-6"} {...props.values} title={null}/>
            </div>
        </Block>
    )
}
