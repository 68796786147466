// Suppress known minified React errors in production
if (process.env.NODE_ENV === 'production') {
    const originalConsoleError = console.error;
    const knownReactErrorPattern = /Minified React error #4(18|23)/;

    // Suppress console.error logging
    console.error = function (...args) {
        const msg = args[0]?.toString() || '';
        if (knownReactErrorPattern.test(msg)) return;
        originalConsoleError.apply(console, args);
    };

    // Catch uncaught errors globally
    window.addEventListener('error', function (event) {
        const msg = event?.error?.message || event?.message || '';
        if (knownReactErrorPattern.test(msg)) {
            event.preventDefault(); // Prevents it from logging to console
        }
    });

    // Catch Promise rejections
    window.addEventListener('unhandledrejection', function (event) {
        const msg = event?.reason?.message || '';
        if (knownReactErrorPattern.test(msg)) {
            event.preventDefault(); // Suppress as well
        }
    });
}
